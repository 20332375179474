.slider__item {
    max-width: 100%;
    height: 650px;
  }
  
  .slider__item-01 {
    background: linear-gradient(rgb(0, 13, 107, 0.5), rgb(0, 13, 107, 0.5)),
      url("../../assets/slider-img/slider-1.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  
  .slider__item-02 {
    background: linear-gradient(rgb(0, 13, 107, 0.5), rgb(0, 13, 107, 0.5)),
      url("../../assets/slider-img/slider-2.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  
  .slider__item-03 {
    background: linear-gradient(rgb(0, 13, 107, 0.5), rgb(0, 13, 107, 0.5)),
      url("../../assets/slider-img/slider-3.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  
  .hero__slider {
    overflow: hidden;
  }
  
  .slider__content {
    padding-top: 150px;
  }
  
  .slider__content h1 {
    font-size: 3.2rem;
    font-weight: 600;
  }
  
  .reserve__btn {
    background: #fff !important;
    color: #000d6b !important;
    border: none;
    outline: none;
  }
  
  .reserve__btn a {
    text-decoration: none;
    font-weight: 600;
    color: #000d6b;
  }
  
  .reserve__btn:hover {
    background: #000d6b;
    color: #fff;
  }
  
  @media only screen and (max-width: 992px) {
    .slider__item {
      height: 530px;
      max-width: 100vw;
    }
  
    .slider__content {
      margin-top: -50px;
    }
  
    .slider__content h1 {
      font-size: 2rem;
    }
  }
  
  @media only screen and (max-width: 768px) {
    .slider__item {
      height: 450px;
    }
  
    .slider__content h1 {
      font-size: 1.5rem;
    }
  }