.wrapper404 {
  height: 100vh;
  width: 100%;
  background: radial-gradient(#653d84, #332042);
  position: relative;
}
.landing-page404 {
  width: 100%;
  height: 100%;
  margin: 0;
  /* box-shadow: 0px 0px 8px 1px #ccc; */
  /* background: #fafafa; */
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.svg404 {
  width: 100%;
  height: 100%;
  margin: 0 0 15px;
}
.h1404 {
  font-size: 48px;
  margin: 0;
  color: #ff0000;
}
.p404 {
  font-size: 16px;
  width: 35%;
  margin: 16px auto 24px;
  text-align: center;
}
.button404 {
  border-radius: 50px;
  padding: 8px 24px;
  font-size: 16px;
  cursor: pointer;
  background: #62ad9b;
  color: #fff;
  border: none;
  box-shadow: 0 4px 8px 0 #ccc;
}
