@-webkit-keyframes bouncy {
  from,
  to {
    -webkit-transform: scale(1, 1);
  }
  25% {
    -webkit-transform: scale(0.9, 1.1);
  }
  50% {
    -webkit-transform: scale(1.1, 0.9);
  }
  75% {
    -webkit-transform: scale(0.95, 1.05);
  }
}
@keyframes bouncy {
  from,
  to {
    transform: scale(1, 1);
  }
  25% {
    transform: scale(0.9, 1.1);
  }
  50% {
    transform: scale(1.1, 0.9);
  }
  75% {
    transform: scale(0.95, 1.05);
  }
}
.bouncy {
  -webkit-animation: bouncy 0.6s;
  animation: bouncy 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-duration: 0.6s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

a {
  text-decoration: none;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
a:hover {
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
a:focus {
  outline: 0;
}
a:active {
  outline: 0;
}
.wrap {
  width: 100%;
  max-width: 1440px;
  float: none;
  margin: 0 auto;
}

.title {
  font-size: 48px;
  font-family: 'Prata', serif;
  margin-bottom: 12px;
}
.subtitle {
  font-size: 18px;
  color: rgba(74, 74, 74, 0.58);
}
.section_content {
  margin-top: 36px;
}
.container {
  overflow: hidden;
}
.page {
  padding-bottom: 20px;
}
.page .posts .tour_item {
  margin-bottom: 40px;
}
.page .posts .tour_item:nth-child(even) {
  margin-right: 0;
}
.page_head {
  position: relative;
  z-index: 1;
}
.page_head .title,
.page_head .subtitle {
  text-align: left;
}
.page .left_content {
  max-width: 1090px;
}
.page .right_content {
  max-width: 308px;
}
.page.favourites-list .page_head .title {
  width: auto;
  margin-bottom: 0;
}
.page.favourites-list .page_head .title span {
  color: #919193;
  display: inline-block;
  margin-left: 10px;
}
.page.favourites-list .page_head .select_wrap {
  width: 310px;
  height: 58px;
  background: #F7F7F7;
  position: relative;
  border-radius: 7px;
  overflow: hidden;
  float: right;
}
.page.favourites-list .page_head .select_wrap:before {
  width: 9px;
  height: 9px;
  background: #D03000;
  position: absolute;
  content: '';
  top: 50%;
  margin-top: -4px;
  right: 24px;
  border-radius: 50%;
}
.page.favourites-list .page_head .select_wrap select {
  width: 100%;
  height: 100%;
  border: 0;
  background: none;
  font-size: 21px;
  color: rgba(145, 145, 147, 0.47);
  padding: 0 50px 0 28px;
  font-weight: 400;
  position: relative;
  z-index: 1;
}
.page.favourites-list .page_head .select_wrap select.selected {
  color: #222;
}
.page.favourites-list .page_head .select_wrap select:focus {
  outline: 0;
}
.page.static-page .page_head {
  margin-bottom: 23px;
}
.page.static-page .left_content .description {
  font-size: 26px;
  font-style: italic;
  color: #222;
  margin-bottom: 12px;
}
.page.static-page .left_content p:not([class]) {
  font-size: 21px;
  color: #919193;
  margin: 12px 0;
}
.page.static-page .left_content h2 {
  font-size: 30px;
  color: #222;
  font-family: "Prata", serif;
  margin: 30px 0 10px;
}
.page.static-page .left_content h3 {
  font-size: 28px;
  color: #222;
  font-family: "Prata", serif;
  margin: 30px 0 10px;
}
.page.static-page .left_content h4 {
  font-size: 27px;
  color: #222;
  font-family: "Prata", serif;
  margin: 30px 0 10px;
}
.page.static-page .left_content h5 {
  font-size: 26px;
  color: #222;
  font-family: "Prata", serif;
  margin: 30px 0 10px;
}
.page.static-page .left_content h6 {
  font-size: 24px;
  color: #222;
  font-family: "Prata", serif;
  margin: 30px 0 10px;
}
.page.static-page .left_content a {
  color: #FF3B00;
  text-decoration: underline;
}
.page.static-page .left_content img {
  margin: 15px 0 20px;
  max-width: 100%;
  height: auto;
  border-radius: 8px;
}
.page.static-page .left_content ul {
  margin-bottom: 20px;
  margin: 10px 0;
}
.page.static-page .left_content ul li {
  font-size: 21px;
  line-height: 1.4;
  position: relative;
  box-sizing: border-box;
  color: #919193;
  padding-left: 22px;
  margin-bottom: 3px;
}
.page.static-page .left_content ul li:before {
  position: absolute;
  content: '';
  background: #222;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  top: 10px;
  left: 4px;
}
.page.static-page .left_content ol {
  margin-bottom: 20px;
  counter-reset: myCounter;
  margin: 10px 0;
}
.page.static-page .left_content ol li {
  font-size: 21px;
  line-height: 1.4;
  position: relative;
  box-sizing: border-box;
  color: #919193;
  padding-left: 22px;
  margin-bottom: 3px;
}
.page.static-page .left_content ol li:before {
  counter-increment: myCounter;
  content: counter(myCounter) ".";
  position: absolute;
  left: 0;
  top: 0;
  color: #222;
}
.page.static-page .left_content b,
.page.static-page .left_content strong {
  font-weight: 600;
  color: #222;
}
.page.static-page .left_content .two-colums .col {
  width: 48%;
  margin-right: 4%;
}
.page.static-page .left_content .two-colums .col:last-child {
  margin-right: 0;
}
.page.static-page.left-sidebar .left_content {
  float: right;
}
.page.static-page.left-sidebar .right_content {
  float: left;
}

/*----------------------------------------------------------------------------------
6. Stories section
-----------------------------------------------------------------------------------*/
.stories {
  overflow: hidden;
  padding: 50px 0;
}
.stories .title_wrap {
  padding-right: 300px;
  position: relative;
}
.stories .controls {
  width: auto;
  position: absolute;
  right: 0;
  bottom: 0;
}
.stories .controls .link {
  font-size: 18px;
  color: #222;
  width: auto;
  display: block;
  float: left;
  line-height: 44px;
  margin-right: 30px;
}
.stories .controls .link:hover {
  color: #FF3B00;
}
.stories .controls .arrows {
  position: relative;
  z-index: 2;
  width: auto;
}
.stories .controls .arrows .arrow {
  width: 44px;
  height: 44px;
  border-radius: 50%;
  background: #C7C7C7;
  position: relative;
  transition: all .2s linear;
}
.stories .controls .arrows .arrow:before {
  width: 16px;
  height: 12px;
  /* background: url(./img/left-arrow.svg) center center no-repeat; */
  background-size: contain;
  position: absolute;
  content: '';
  top: 50%;
  left: 50%;
  margin-top: -6px;
  margin-left: -8px;
  opacity: 1;
  transition: all .2s linear;
}
.stories .controls .arrows .arrow:after {
  width: 16px;
  height: 12px;
  /* background: url(../img/right-arrow.svg) center center no-repeat; */
  background-size: contain;
  position: absolute;
  content: '';
  top: 50%;
  left: 50%;
  margin-top: -6px;
  margin-left: -8px;
  opacity: 0;
  transition: all .2s linear;
  transform: rotate(180deg);
}
.stories .controls .arrows .arrow.next {
  margin-left: 18px;
  transform: rotate(180deg);
}
.stories .controls .arrows .arrow:hover {
  background: rgba(199, 199, 199, 0.18);
  transition: all .2s linear;
  cursor: pointer;
}
.stories .controls .arrows .arrow:hover:before {
  opacity: 0;
  transition: all .2s linear;
}
.stories .controls .arrows .arrow:hover:after {
  opacity: 1;
  transition: all .2s linear;
}
.stories .slick-list {
  overflow: visible;
}
.story_item {
  width: 260px;
  height: 400px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  margin-right: 35px;
  border-radius: 13px;
  display: block;
  float: left;
}
.story_item:hover .shadow {
  opacity: 1;
  transition: all 0.2s ease-out;
}
.story_item:hover ._content {
  transform: none;
  transition: all 0.2s ease-in;
}
.story_item:hover ._content .text {
  opacity: 1;
  transition: all 0.2s ease-in;
}
.story_item .shadow {
  filter: blur(10px);
  -webkit-filter: blur(10px);
  -moz-filter: blur(10px);
  position: absolute;
  top: 10px;
  left: -6px;
  right: -6px;
  bottom: -10px;
  z-index: -1;
  width: auto;
  opacity: 0;
  transition: all 0.21s ease-out;
  will-change: opacity;
}
.story_item:before {
  background: -moz-linear-gradient(top, rgba(0, 0, 0, 0) 0%, #000000 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 0%, #000000 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, #000000 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00000000', endColorstr='#000000', GradientType=0);
  /* IE6-9 */
  opacity: .77;
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 50%;
  border-radius: 13px;
}
.story_item .item_wrap {
  position: relative;
  height: 100%;
  overflow: hidden;
}
.story_item ._content {
  position: relative;
  height: 100%;
  text-align: center;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  transform: translateY(60px);
  transition: all 0.2s ease-in;
}
.story_item ._content .flag {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  display: inline-block;
  float: none;
  overflow: hidden;
}
.story_item ._content .flag img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.story_item ._content .country {
  font-size: 23px;
  color: #fff;
  font-family: 'Prata', serif;
  margin-top: 20px;
}
.story_item ._content .text {
  font-size: 17px;
  color: rgba(255, 255, 255, 0.56);
  margin-top: 8px;
  opacity: 0;
  font-family: Arial, Helvetica, sans-serif;
  height: 56px;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  transition: all 0.2s ease-in;
}
/*----------------------------------------------------------------------------------
7. Blog section
-----------------------------------------------------------------------------------*/
.blog {
  padding: 50px 0;
}
.blog .title_wrap {
  padding-right: 300px;
  position: relative;
}
.blog .controls {
  width: auto;
  position: absolute;
  right: 0;
  bottom: 0;
}
.blog .controls .link {
  font-size: 18px;
  color: #222;
  width: auto;
  display: block;
  float: left;
  line-height: 44px;
}
.blog .controls .link:hover {
  color: #FF3B00;
}
.blog_item {
  position: relative;
  width: 456px;
  width: 32%;
  margin-right: 2%;
  display: block;
  float: left;
}
.blog_item:nth-child(3n) {
  margin-right: 0;
}
.blog_item:hover .blog_item_top .shadow {
  opacity: 1;
  transition: all 0.2s ease-out;
}
.blog_item_top {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  border-radius: 13px;
  margin-bottom: 20px;
}
.blog_item_top .car_tags .car_tag {
  height: 28px;
  line-height: 28px;
  background: #000;
  width: auto;
  padding: 0 10px;
  border-radius: 5px;
  font-size: 15px;
  color: #fff;
  margin-right: 8px;
}
.blog_item_top .car_tags .car_tag.red {
  background: #D03000;
}
.blog_item_top .car_tags .car_tag.green {
  background: #2FB26B;
}
.blog_item_top .car_tags .car_tag.blue {
  background: #1140DE;
}
.blog_item_top .car_tags .car_tag.black {
  background: #222;
}
.blog_item_top:before {
  background: -moz-linear-gradient(top, rgba(0, 0, 0, 0) 0%, #000000 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 0%, #000000 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, #000000 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00000000', endColorstr='#000000', GradientType=0);
  /* IE6-9 */
  opacity: .63;
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 13px;
}
.blog_item_top .sq_parent {
  width: 100%;
  position: relative;
  padding-bottom: 60%;
  overflow: hidden;
}
.blog_item_top .sq_parent .sq_wrap {
  position: absolute;
  width: 100%;
  height: 100%;
  padding: 20px 30px;
}
.blog_item_top .sq_parent .sq_wrap .sq_content {
  float: left;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.blog_item_top ._title {
  font-size: 25px;
  font-family: 'Prata', serif;
  color: #fff;
}
.blog_item_top .shadow {
  filter: blur(10px);
  position: absolute;
  top: 10px;
  left: -6px;
  right: -6px;
  bottom: -10px;
  z-index: -1;
  width: auto;
  opacity: 0;
  transition: all 0.21s ease-out;
  will-change: opacity;
}
.blog_item_bottom .author {
  padding-left: 60px;
  position: relative;
  margin-bottom: 15px;
}
.blog_item_bottom .author .userpic {
  width: 42px;
  height: 42px;
  border-radius: 50%;
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
}
.blog_item_bottom .author .userpic img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.blog_item_bottom .author .date {
  color: #919193;
  font-size: 15px;
  height: 42px;
  display: table-cell;
  vertical-align: middle;
  float: none;
}
.blog_item_bottom .text {
  font-size: 21px;
  color: #919193;
}
/*----------------------------------------------------------------------------------
12. Media queries
-----------------------------------------------------------------------------------*/

@media screen and (max-width: 1520px) {
  .wrap {
    max-width: 1300px;
  }
  .tour_item {
    width: 630px;
  }
  .tour_item_bottom ._title {
    font-size: 28px;
  }

  .blog_item_bottom .text {
    font-size: 18px;
  }
  .story_item ._content .country {
    font-size: 30px;
  }
  .top_panel .menu_wrap {
    width: 864px;
  }
  .popup {
    width: 570px;
  }
  .popup .scroll .scroll_wrap {
    padding-top: 100px;
  }
  .popup .close {
    top: 30px;
    right: 30px;
  }
  .search-form .btn {
    margin-top: -7px;
  }
}
@media screen and (max-width: 1400px) {
  .wrap {
    max-width: 1280px;
  }
  .tour_item {
    width: 620px;
  }
  .top_panel {
    top: 34px;
  }
  .most_popular {
    padding: 40px 0;
    padding-top: 80px;
  }
  .title {
    font-size: 42px;
  }
  .destinations,
  .search-tour,
  .stories,
  .blog,
  .subscribe_section {
    padding: 40px 0;
  }
  .instagram_posts {
    padding-top: 40px;
  }
  .top_panel .menu_wrap {
    width: 844px;
  }
  .popup .scroll .scroll_wrap {
    padding-top: 86px;
    padding-bottom: 40px;
  }
  .popup.profile-setting .scroll .scroll_wrap {
    padding-top: 100px;
  }
  .popup-body .form .input {
    height: 50px;
    font-size: 17px;
    margin-bottom: 10px;
  }
  .popup-body .form .textarea {
    font-size: 17px;
    margin-bottom: 10px;
  }
  .popup-body .form .submit {
    height: 50px;
  }
  .popup-head .title {
    font-size: 40px;
  }
  .popup-body .form .label {
    margin-bottom: 10px;
    margin-top: 8px;
  }
  .top_panel .right .tel {
    margin-right: 26px;
  }
  .top_panel .center .menu ul li {
    margin-right: 40px;
  }
}
@media screen and (max-width: 1300px) {
  .wrap {
    max-width: 1180px;
  }
  .top_panel .center .menu ul li {
    margin-right: 36px;
  }
  .top_panel .left .search_btn {
    margin-right: 28px;
  }
  .top_panel .right .favorites-count ._text {
    display: none;
  }
  .main_slider .arrows {
    margin-right: -590px;
  }
  .tour_item {
    width: 570px;
  }
  .search-form {
    padding: 40px 30px;
    height: auto;
  }
  .search-form .num-col {
    width: 146px;
  }
  .search-form .num-col.last {
    width: 170px;
  }
  .search-form .btn {
    right: 30px;
  }
  .subscribe_section .subscribe_block {
    padding: 60px 50px;
  }
  .top_panel .menu_wrap {
    width: 744px;
  }
  .load_more {
    width: 92px;
    height: 92px;
    font-size: 16px;
  }
  .search-form .btn {
    margin-top: -5px;
  }
}
@media screen and (min-width: 1201px) {
  .top_panel .menu_wrap {
    display: block !important;
  }
}
@media screen and (max-width: 1200px) {
  .top_panel {
    z-index: 3;
  }
  .top_panel .currency {
    display: none;
  }
  .top_panel .right {
    display: none;
  }
  .top_panel .user {
    display: none;
  }
  .top_panel .mobile_btn {
    display: block;
  }
  .top_panel .wrap_float {
    padding: 0;
  }
  .wrap {
    max-width: 940px;
  }
  .top_panel .center {
    display: none;
  }
  .main_slider .slider_wrap .slide .slide_content .title_wrap .country {
    width: 100%;
    text-align: left;
  }
  .main_slider .slider_wrap .slide .slide_content .title_wrap .country:before {
    top: 9px;
  }
  .main_slider .arrows {
    margin-right: -470px;
  }
  .main_slider .slider_wrap .slide .slide_content .next_title {
    display: none !important;
  }
  .destinations_item ._content ._title {
    margin-bottom: 5px;
  }
  .search-form .date-col:nth-child(2) {
    clear: both;
  }
  .search-form .destination-col {
    max-width: 576px;
    margin-right: 0;
    width: 100%;
    margin-bottom: 30px;
  }
  .search-form .label {
    margin-bottom: 16px;
  }
  .search-form .destination-col .label {
    margin-bottom: 16px;
  }
  .blog_item {
    width: 48%;
    margin-right: 4%;
  }
  .blog_item:nth-child(2) {
    margin-right: 0;
  }
  .blog_item:last-child {
    display: none;
  }
  .subscribe_section .subscribe_block .left {
    width: 100%;
    text-align: center;
    margin-bottom: 20px;
  }
  .subscribe_section .subscribe_block ._title,
  .subscribe_section .subscribe_block ._subtitle {
    width: 100%;
  }
  .subscribe_section .subscribe_block .right {
    width: 100%;
  }
  .subscribe_section .subscribe_block .right .input_wrap {
    width: 34vw;
  }
  .instagram_posts .post {
    width: 25%;
  }
  .instagram_posts .post:last-child {
    display: none;
  }
  .footer_top .left {
    width: 100%;
  }
  .footer_top .right {
    width: 100%;
    margin-top: 30px;
  }
  .footer_top .right .contacts_info .tel {
    max-width: 300px;
  }
  .footer_bottom .right {
    clear: both;
    float: left;
    width: 100%;
    margin-top: 14px;
  }
  .footer_bottom {
    padding-top: 50px;
  }
  .top_panel .menu_wrap {
    display: none;
    width: 100%;
    background: #000;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    overflow: auto;
    z-index: 4;
  }
  .top_panel .menu_wrap .close {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    background: rgba(255, 255, 255, 0.33);
    float: right;
    position: absolute;
    right: 0;
    top: 34px;
    transition: all .2s linear;
  }
  .top_panel .menu_wrap .close.opened {
    transform: rotate(45deg);
    transition: all .2s linear;
    transition-delay: .3s;
  }
  .top_panel .menu_wrap .close.opened span:nth-child(1) {
    top: 23px;
    transition: all .2s linear;
  }
  .top_panel .menu_wrap .close.opened span:nth-child(2) {
    transform: rotate(90deg);
    transition: all .3s linear;
    transition-delay: .5s;
  }
  .top_panel .menu_wrap .close.opened span:nth-child(3) {
    top: 23px;
    transition: all .2s linear;
  }
  .top_panel .menu_wrap .close span {
    height: 3px;
    border-radius: 2px;
    background: #fff;
    position: absolute;
    display: block;
    width: 20px;
    left: 14px;
  }
  .top_panel .menu_wrap .close span:nth-child(1) {
    top: 16px;
    transition: all .4s linear;
    transition-delay: .6s;
  }
  .top_panel .menu_wrap .close span:nth-child(2) {
    top: 23px;
  }
  .top_panel .menu_wrap .close span:nth-child(3) {
    top: 30px;
    transition: all .4s linear;
    transition-delay: .6s;
  }
  .top_panel .user {
    display: block;
    left: 50%;
    margin-left: -470px;
    top: 34px;
    margin-top: 0;
  }
  .top_panel .right {
    display: block;
  }
  .top_panel .right .favorites-count {
    display: block;
    position: absolute;
    left: 50%;
    margin-left: -400px;
    top: 34px;
  }
  .top_panel .right .favorites-count ._text {
    display: block;
    color: #fff;
  }
  .top_panel .right .tel {
    display: none;
  }
  .top_panel .center {
    display: block;
    width: 940px;
    left: 50%;
    position: relative;
    margin-left: -470px;
    padding-top: 112px;
    padding-bottom: 50px;
  }
  .top_panel .center .menu {
    width: 100%;
  }
  .top_panel .center .menu ul {
    width: 100%;
  }
  .top_panel .center .menu ul li {
    width: 100%;
    text-align: left;
    margin-right: 0;
  }
  .top_panel .center .menu ul li a {
    font-size: 28px;
    font-family: 'Prata', serif;
    width: 100%;
    display: block;
    float: left;
    border-bottom: 1px solid rgba(255, 255, 255, 0.18);
    line-height: 80px;
    margin-right: 0;
  }
  .top_panel .center .menu ul li a.active span {
    position: relative;
    padding-right: 20px;
  }
  .top_panel .center .menu ul li a.active span:before {
    width: 9px;
    height: 9px;
    background: #d03000;
    right: 0;
    top: 50%;
    margin-top: -4px;
    content: '';
    border-radius: 50%;
    position: absolute;
  }
  .top_panel .center .menu ul li a span {
    border-bottom: 0;
  }
  .top_panel .center .menu ul li a:hover span {
    border-bottom: 0;
    transition: none;
  }
  .top_panel .center .menu ul li.dropdown_li a.hover:before {
    background: #fff;
    transition: all .2s linear;
  }
  .top_panel .center .menu ul li.dropdown_li a.hover:after {
    /* background-image: url(../img/minus.svg); */
  }
  .top_panel .center .menu ul li.dropdown_li a:before {
    width: 44px;
    height: 44px;
    background: rgba(255, 255, 255, 0.44);
    border-radius: 50%;
    content: '';
    position: absolute;
    right: 0;
    left: auto;
    top: 50%;
    margin-top: -22px;
    transition: all .2s linear;
    display: block;
  }
  .top_panel .center .menu ul li.dropdown_li a:after {
    width: 12px;
    height: 12px;
    /* background: url(../img/plus.svg) center center no-repeat; */
    background-size: contain;
    position: absolute;
    content: '';
    top: 50%;
    margin-top: -6px;
    right: 16px;
  }
  .top_panel .center .menu ul li .dropdown_ul {
    border-bottom: 1px solid rgba(255, 255, 255, 0.18);
    border-radius: 0;
    padding-left: 0;
    padding-right: 0;
  }
  .top_panel .center .menu ul li .dropdown_ul li a {
    border-bottom: 0;
  }
  .top_panel .center .menu ul li .dropdown_ul li a:before,
  .top_panel .center .menu ul li .dropdown_ul li a:after {
    display: none;
  }
  .top_panel .center .menu .currency_mob {
    position: relative;
    padding-left: 60px;
    margin-top: 30px;
    margin-bottom: 20px;
  }
  .top_panel .center .menu .currency_mob select {
    width: 48px;
    height: 48px;
    border: 0;
    border-radius: 50%;
    background: rgba(152, 152, 152, 0.33);
    font-size: 15px;
    font-weight: 700;
    color: #fff;
    text-transform: uppercase;
    padding: 0 8px;
    position: absolute;
    left: 0;
    top: 0;
  }
  .top_panel .center .menu .currency_mob select:hover {
    cursor: pointer;
  }
  .top_panel .center .menu .currency_mob p {
    font-size: 18px;
    color: rgba(255, 255, 255, 0.38);
    line-height: 48px;
  }
  .top_panel .center .menu .mobile_content {
    display: block;
  }
  .top_panel .center .menu .mobile_content .tel {
    margin-bottom: 27px;
  }
  .top_panel .center .menu .mobile_content .tel a {
    font-size: 26px;
    font-weight: 600;
    color: #fff;
  }
  .top_panel .center .menu .mobile_content .tel p {
    font-size: 18px;
    color: rgba(255, 255, 255, 0.37);
  }
  .top_panel .center .menu .mobile_content .link {
    width: 45px;
    height: 45px;
    border-radius: 50%;
    display: block;
    float: left;
    position: relative;
    margin-right: 23px;
  }
  .top_panel .center .menu .mobile_content .link:last-child {
    margin-right: 0;
  }
  .top_panel .center .menu .mobile_content .link span {
    display: block;
    position: relative;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    z-index: 1;
    background: inherit;
  }
  .top_panel .center .menu .mobile_content .link span:before {
    position: absolute;
    top: 50%;
    left: 50%;
    content: '';
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
  }
  .top_panel .center .menu .mobile_content .link.facebook {
    background: #33589E;
  }
  .top_panel .center .menu .mobile_content .link.facebook span:before {
    width: 13px;
    height: 25px;
    /* background-image: url(../img/facebook-logo.svg); */
    margin-top: -13px;
    margin-left: -7px;
  }
  .top_panel .center .menu .mobile_content .link.instagram {
    background: #4c5ad1;
    /* Old browsers */
    background: -moz-linear-gradient(-45deg, #4c5ad1 0%, #ff3756 42%, #ff3e37 60%, #ffd42b 100%);
    /* FF3.6-15 */
    background: -webkit-linear-gradient(-45deg, #4c5ad1 0%, #ff3756 42%, #ff3e37 60%, #ffd42b 100%);
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(135deg, #4c5ad1 0%, #ff3756 42%, #ff3e37 60%, #ffd42b 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#4c5ad1', endColorstr='#ffd42b', GradientType=1);
    /* IE6-9 fallback on horizontal gradient */
  }
  .top_panel .center .menu .mobile_content .link.instagram span:before {
    width: 22px;
    height: 22px;
    /* background-image: url(../img/instagram-icon.svg); */
    margin-top: -11px;
    margin-left: -11px;
  }
  .top_panel .center .menu .mobile_content .link.pinterest {
    background: #C8232C;
  }
  .top_panel .center .menu .mobile_content .link.pinterest span:before {
    width: 20px;
    height: 24px;
    /* background-image: url(../img/pinterest.svg); */
    margin-top: -12px;
    margin-left: -10px;
  }
  .top_panel .center .menu .mobile_content .link.twitter {
    background: #4BA0EB;
  }
  .top_panel .center .menu .mobile_content .link.twitter span:before {
    width: 26px;
    height: 20px;
    /* background-image: url(../img/twitter.svg); */
    margin-top: -10px;
    margin-left: -13px;
  }
  .top_panel .center .menu .mobile_content .link.youtube {
    background: #FF000E;
  }
  .top_panel .center .menu .mobile_content .link.youtube span:before {
    width: 23px;
    height: 16px;
    /* background-image: url(../img/youtube.svg); */
    margin-top: -8px;
    margin-left: -12px;
  }
  .top_panel .center .menu ul li .dropdown_ul {
    position: static;
    width: 100%;
    background: none;
  }
  .top_panel .center .menu ul li .dropdown_ul li a {
    font-size: 18px;
    color: rgba(255, 255, 255, 0.38);
    font-family: 'Nunito', sans-serif;
  }
  .top_panel .center .menu ul li.dropdown_li > a {
    pointer-events: inherit;
  }
  .page .posts .tour_item {
    width: 49%;
    margin-right: 2%;
    margin-bottom: 2%;
  }
  .tour_item_bottom ._info_right {
    float: left;
    margin-top: 6px;
    clear: both;
  }
  .tour_item_bottom ._title {
    font-size: 24px;
    margin-bottom: 17px;
  }
  .breadcrumbs {
    padding-top: 110px;
  }
  .top_panel.inversion .mobile_btn {
    background: #c7c7c7;
  }
  .top_panel.inversion .center .menu ul li a {
    color: #fff;
  }
  .top_panel.inversion .right .favorites-count ._text {
    color: #fff;
  }
  .top_panel.inversion .user {
    background: rgba(255, 255, 255, 0.33);
  }
  .page.static-page .left_content .description {
    font-size: 20px;
  }
  .page.static-page .left_content p:not([class]) {
    font-size: 18px;
  }
  .page.static-page .left_content .two-colums .col {
    margin-right: 0;
    width: 100%;
  }
  .page.static-page .left_content ul li,
  .page.static-page .left_content ol li {
    font-size: 18px;
  }
  .page.static-page .left_content ul li:before {
    top: 8px;
  }
  .top_panel .user .usermenu {
    right: auto;
    left: 0;
    border-radius: 0 20px 20px 20px;
  }
  .top_panel .user .userlink:hover {
    background: rgba(255, 255, 255, 0.33);
  }
  .top_panel .user .userlink:hover:before {
    opacity: 1;
  }
  .top_panel .user .userlink:hover:after {
    display: none;
  }
  .footer_top .right .contacts_info .tel a {
    font-size: 24px;
  }
  .search-form .btn {
    margin-top: 55px;
  }
  .story_item ._content .text {
    height: 52px;
  }
}
@media screen and (max-width: 1040px) {
  .destinations_item .shadow,
  .tour_item .shadow,
  .story_item .shadow,
  .blog_item_top .shadow,
  .social-links .link:after {
    display: none;
  }
  .destinations_item ._content,
  .story_item ._content {
    transform: none;
  }
  .destinations_item ._content ._info,
  .story_item ._content .text {
    opacity: 1;
  }
  .mobile:not(.ie-browser) .search-form .date-col .date_div .desctop-input {
    display: none;
  }
  .mobile:not(.ie-browser) .search-form .date-col .date_div .mobile-input {
    display: block;
    z-index: 1;
  }
  .mobile:not(.ie-browser) .ui-widget.ui-widget-content {
    display: none !important;
  }
  .mobile .main_slider .slider_wrap .slide .wrap_float {
    height: inherit;
  }
  .mobile .most_popular .wrap {
    width: 100%;
    max-width: 100%;
  }
  .mobile .most_popular .controls .arrows {
    display: none;
  }
  .mobile .most_popular .title_wrap {
    padding-left: 4%;
    padding-right: 4%;
  }
  .mobile .most_popular .section_content {
    overflow: auto;
  }
  .mobile .most_popular .section_content .tour-slider {
    white-space: nowrap;
    width: auto;
    padding-left: 4%;
  }
  .mobile .most_popular .section_content .tour-slider .tour_item {
    display: inline-block;
    float: none;
    vertical-align: top;
    white-space: normal;
    margin-bottom: 20px;
  }
  .mobile .most_popular .section_content .tour-slider .tour_item:last-child {
    margin-right: 4vw;
  }
  .mobile .stories .wrap {
    width: 100%;
    max-width: 100%;
  }
  .mobile .stories .controls .arrows {
    display: none;
  }
  .mobile .stories .title_wrap {
    padding-left: 4%;
    padding-right: 4%;
  }
  .mobile .stories .section_content {
    overflow: auto;
  }
  .mobile .stories .section_content .stries_slider {
    white-space: nowrap;
    width: auto;
    padding-left: 4%;
  }
  .mobile .stories .section_content .stries_slider .story_item {
    display: inline-block;
    float: none;
    vertical-align: top;
    white-space: normal;
    margin-bottom: 20px;
    margin-right: 23px;
  }
  .mobile .stories .section_content .stries_slider .story_item:last-child {
    margin-right: 4vw;
  }
  .safari-browser .search-form .num-col .val {
    padding-top: 10px;
  }
  .main_slider .arrows .arrow:hover {
    background: rgba(255, 255, 255, 0.42);
  }
  .main_slider .arrows .arrow:hover:before {
    opacity: 1;
  }
  .main_slider .arrows .arrow:hover:after {
    opacity: 0;
  }
  .most_popular .controls .link,
  .destinations .controls .link,
  .stories .controls .link,
  .blog .controls .link {
    color: #ff3b00;
  }
  .popup-head .link,
  .popup-body .form .link {
    text-decoration: underline;
  }
}
@media screen and (max-width: 1040px) and (orientation: landscape) {
  .main_slider,
  .main_slider .slider_wrap .slide .wrap_float {
    height: 460px !important;
  }
  .main_slider {
    position: relative;
  }
}
@media screen and (min-width: 1001px) {
  .footer_top .col ul {
    display: block !important;
  }
}
@media screen and (max-width: 1000px) {
  .wrap {
    max-width: 100%;
    width: 92%;
  }
  .main_slider .arrows {
    right: 4%;
    margin-right: 0;
  }
  .destinations_item {
    width: 300px;
  }
  .destinations .wrap {
    width: 100%;
  }
  .destinations .title_wrap {
    padding-left: 4%;
    padding-right: 4%;
  }
  .destinations .controls {
    right: 4%;
  }
  .destinations .section_content {
    overflow: auto;
  }
  .destinations .section_content .scroll {
    width: auto;
    white-space: nowrap;
    padding-left: 4%;
  }
  .destinations .section_content .scroll .destinations_item {
    width: 300px;
    display: inline-block;
    float: none;
    margin-right: 20px;
    vertical-align: top;
    margin-bottom: 20px;
  }
  .destinations .section_content .scroll .destinations_item:last-child {
    margin-right: 4vw;
  }
  .search-form .destination-col {
    max-width: 100%;
  }
  .search-form .num-col:nth-child(4) {
    clear: both;
  }
  .search-form .date-col {
    width: 50%;
    margin-right: 0;
    border-right: 0;
    padding-bottom: 20px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.24);
  }
  .search-form .num-col {
    width: 50%;
    border-right: 0;
    padding-top: 30px;
  }
  .search-form .num-col.last {
    padding-left: 0;
  }
  .search-form .btn {
    position: relative;
    right: auto;
    top: auto;
    margin-top: 0;
    clear: both;
    padding-left: 70px;
    background: none;
    width: auto;
    margin-top: 40px;
    border-radius: 0;
  }
  .search-form .btn:after {
    width: 54px;
    height: 54px;
    background: #d03000;
    border-radius: 50%;
    position: absolute;
    top: 0;
    left: 0;
    content: '';
  }
  .search-form .btn:before {
    z-index: 2;
    left: 28px;
  }
  .search-form .btn span {
    display: block;
    font-size: 21px;
    color: #fff;
  }
  .blog_item_top ._title {
    font-size: 22px;
  }
  .subscribe_section .subscribe_block .right .input_wrap.select_wrap {
    width: 48%;
    margin-right: 4%;
  }
  .subscribe_section .subscribe_block .right .submit {
    padding-left: 70px;
    background: none;
    margin-left: 0;
    right: auto;
    width: auto;
    margin-top: 30px;
  }
  .subscribe_section .subscribe_block .right .submit:after {
    width: 54px;
    height: 54px;
    background: #d03000;
    border-radius: 50%;
    content: '';
    left: 0;
    top: 0;
    position: absolute;
  }
  .subscribe_section .subscribe_block .right .submit:before {
    z-index: 1;
    left: 15px;
    margin-left: 0;
    margin-top: -10px;
  }
  .subscribe_section .subscribe_block .right .submit span {
    display: block;
    font-size: 21px;
    color: #fff;
  }
  .main_slider .slider_wrap .slide.slick-active .slide_content .title_wrap {
    width: 100%;
  }
  .footer_top .col {
    width: 100%;
    border-bottom: 1px solid rgba(255, 255, 255, 0.18);
    padding-right: 0;
  }
  .footer_top .col ul {
    display: none;
    padding-bottom: 16px;
  }
  .footer_top .left ._title {
    margin-bottom: 0;
    padding: 20px 0;
    font-size: 28px;
    position: relative;
  }
  .footer_top .left ._title.active:before {
    background: #fff;
    transition: all .2s linear;
  }
  .footer_top .left ._title:before {
    width: 44px;
    height: 44px;
    background: rgba(255, 255, 255, 0.44);
    border-radius: 50%;
    content: '';
    position: absolute;
    right: 0;
    top: 50%;
    margin-top: -22px;
    transition: all .2s linear;
  }
  .footer_top .left ._title:after {
    width: 12px;
    height: 12px;
    /* background: url(../img/plus.svg) center center no-repeat; */
    background-size: contain;
    position: absolute;
    content: '';
    top: 50%;
    margin-top: -6px;
    right: 16px;
  }
  .footer_top .right {
    margin-top: 0;
  }
  .footer_top .right ._title {
    padding-top: 20px;
    margin-bottom: 23px;
  }
  .main_slider .slider_wrap .slide .slide_content .title_wrap {
    max-width: 100%;
  }
  .search-form .num-col .buttons button {
    width: 42px;
    height: 42px;
    background: #1140de;
  }
  .search-form .num-col .buttons {
    width: 42px;
  }
  .search-form .num-col .val {
    margin-left: 16px;
    height: 100px;
  }
  .ui-widget.ui-widget-content {
    left: 50% !important;
    transform: translateX(-50%);
    border-radius: 15px;
  }
  .top_panel .center {
    width: 92%;
    left: 4%;
    margin-left: 0;
  }
  .top_panel .user {
    left: 4%;
    margin-left: 0;
  }
  .top_panel .right .favorites-count {
    left: 4%;
    margin-left: 60px;
  }
  .page .posts .tour_item {
    padding: 20px;
  }
  .tour_item_bottom {
    padding: 0 20px 20px;
  }
  .modal_search .search-tour {
    background: #0030d0;
  }
  .pagination ul {
    margin: 0 3px;
  }
}
@media screen and (max-width: 760px) {
  .stories .title_wrap {
    padding-right: 0;
  }
  .blog .title_wrap {
    padding-right: 0;
  }
  .most_popular .controls,
  .destinations .controls,
  .stories .controls,
  .blog .controls {
    position: relative;
    right: auto;
    width: 100%;
    text-align: center;
  }
  .most_popular .controls .link,
  .destinations .controls .link,
  .stories .controls .link,
  .blog .controls .link {
    display: inline-block;
    float: none;
    margin-right: 0;
    margin-top: 20px;
  }
  .most_popular .controls .arrows,
  .destinations .controls .arrows,
  .stories .controls .arrows,
  .blog .controls .arrows {
    width: 100%;
  }
  .most_popular .controls .arrows .arrow,
  .destinations .controls .arrows .arrow,
  .stories .controls .arrows .arrow,
  .blog .controls .arrows .arrow {
    display: inline-block;
    float: none;
  }
  .section_content {
    margin-top: 18px;
  }
  .story_item {
    margin-right: 25px;
  }
  .blog_item {
    width: 100%;
  }
  .blog_item {
    margin-bottom: 38px;
  }
  .tour_item {
    margin-right: 30px;
  }
  .instagram_posts .post {
    width: 50%;
  }
  .blog_item_top ._title {
    font-size: 25px;
  }
  .blog_item:last-child {
    display: block;
    margin-bottom: 0;
  }
  .mobile .most_popular .title_wrap,
  .mobile .stories .title_wrap {
    padding-right: 4%;
  }
  .page .posts .tour_item {
    width: 100%;
    margin-right: 0;
    margin-bottom: 20px;
  }
  .page.favourites-list .page_head .select_wrap {
    clear: both;
    float: left;
    margin-top: 14px;
  }
  .popup-body .subtitle {
    text-align: left;
  }
  .description-block .title {
    text-align: left;
  }
  .pagination {
    text-align: center;
  }
}
@media screen and (max-width: 640px) {
  .main_slider .slider_wrap .slide .slide_content .title_wrap .country {
    top: -44px;
  }
  .top_panel .left {
    width: 100%;
    padding-right: 0;
    text-align: center;
  }
  .top_panel .left .search_btn {
    margin-right: 0;
    position: absolute;
    left: 0;
    top: 0;
  }
  .top_panel .mobile_btn {
    position: absolute;
    right: 0;
    top: 0;
  }
  .top_panel .left .logo {
    display: inline-block;
    float: none;
  }
  .main_slider .slider_wrap .slide .slide_content .buttons .btn {
    font-size: 19px;
  }
  .main_slider .slider_wrap .slide .slide_content .buttons .link {
    margin-left: 22px;
  }
  .main_slider .arrows .arrow {
    margin-left: 0;
  }
  .main_slider .arrows .arrow.prev {
    display: none !important;
  }
  .main_slider .arrows .arrow.next {
    margin-left: 0;
  }
  .main_slider .arrows {
    width: 44px;
  }
  .main_slider .slider_wrap .slide .slide_content .buttons .btn {
    height: 44px;
    line-height: 44px;
  }
  .main_slider .slider_wrap .slide .slide_content .buttons .link {
    height: 44px;
    line-height: 44px;
  }
  .most_popular {
    padding-top: 50px;
  }
  .tour_item {
    margin-right: 0;
    margin: 0 1px;
    padding: 24px;
  }
  .tour_item_bottom {
    padding: 0 24px 24px;
  }
  .tour_item_bottom ._info_right .rating-text {
    display: none;
  }
  .tour_item_bottom ._info_right .rating-stars {
    margin-right: 0;
  }
  .tour_item_bottom ._info_right {
    clear: both;
    float: left;
    width: 100%;
  }
  .tour_item_bottom ._info_left .days {
    font-size: 18px;
  }
  .tour_item_bottom ._info_left .cost {
    font-size: 18px;
  }
  .tour_item_bottom ._title {
    margin-bottom: 13px;
  }
  .tour_item_bottom ._title {
    font-size: 25px;
  }
  .most_popular .slick-list {
    overflow: hidden;
  }
  .tour_item_top .add_bookmark {
    width: 50px;
    height: 50px;
  }
  .tour_item_top .add_bookmark .fav-overlay {
    width: 50px;
    height: 50px;
  }
  .tour_item_top .add_bookmark .is-added {
    width: 14px;
    margin-left: -7px;
  }
  .tour_item_top .add_bookmark .not-added {
    width: 14px;
    margin-left: -7px;
  }
  .most_popular {
    padding-bottom: 25px;
  }
  .destinations,
  .search-tour,
  .stories,
  .blog,
  .subscribe_section {
    padding: 25px 0;
  }
  .search-tour .wrap {
    max-width: 100%;
    width: 100%;
  }
  .search-tour .search-form {
    padding: 40px 4%;
    border-radius: 0;
  }
  .search-form .date-col .date_div_right {
    clear: both;
    margin-left: 0;
    padding-top: 0;
  }
  .search-form .date-col .day {
    line-height: 1.2;
  }
  .blog_item_top .sq_parent .sq_wrap {
    padding: 20px;
  }
  .subscribe_section .subscribe_block {
    padding: 45px 18px;
  }
  .subscribe_section .subscribe_block ._title {
    font-size: 31px;
  }
  .subscribe_section .subscribe_block .right .input_wrap.select_wrap {
    width: 100%;
    margin-right: 0;
    margin-bottom: 14px;
  }
  .subscribe_section .subscribe_block .right .input_wrap.select_wrap select {
    font-size: 18px;
  }
  .subscribe_section .subscribe_block .right .input_wrap {
    width: 100%;
  }
  .subscribe_section .subscribe_block .right .input_wrap .input {
    font-size: 18px;
  }
  .subscribe_section .subscribe_block .right {
    padding-bottom: 0;
  }
  .footer_top .left ._title {
    font-size: 24px;
  }
  .footer_top .right .contacts_info .tel {
    width: 100%;
    padding-right: 0;
  }
  .footer_top .right .contacts_info .email {
    width: 100%;
    margin-top: 17px;
  }
  .main_slider .slider_wrap .slide .slide_content {
    padding-bottom: 22px;
  }
  .main_slider .arrows {
    bottom: 22px;
  }
  .top_panel {
    top: 18px;
    height: 48px;
  }
  .modal_search .close {
    right: 4%;
    top: 18px;
  }
  .modal_search .close:hover:before {
    transform: none;
  }
  .mobile .most_popular .section_content .tour-slider .tour_item {
    width: 300px;
    margin-right: 20px;
    height: 340px;
  }
  .top_panel .user {
    top: 18px;
  }
  .top_panel .right .favorites-count {
    top: 18px;
  }
  .top_panel .menu_wrap .close {
    top: 18px;
  }
  .top_panel .center {
    padding-top: 80px;
  }
  .tour_item_top .country {
    height: 50px;
  }
  .description-block {
    padding-top: 30px;
  }
  .breadcrumbs {
    padding-top: 85px;
  }
  .page.favourites-list .page_head .select_wrap {
    width: 100%;
    max-width: 100%;
  }
  .popup {
    width: 100%;
  }
  .popup .scroll .scroll_wrap {
    padding-left: 18px;
    padding-right: 18px;
    padding-top: 85px;
  }
  .popup .close {
    top: 20px;
    right: 20px;
  }
  .popup-head .title {
    font-size: 31px;
  }
  .popup-body .subtitle {
    text-align: left;
  }
  .popup-head {
    margin-bottom: 12px;
  }
  .popup-body .form .input {
    margin-bottom: 13px;
  }
  .popup-head,
  .popup-body,
  .popup-foot {
    max-width: 100%;
  }
  .popup .select-userpic {
    left: 18px;
    top: 20px;
  }
  .popup.success-popup .popup-head .title:before {
    width: 38px;
    height: 38px;
    top: 0;
  }
  .popup.success-popup .popup-head .title:after {
    left: 11px;
    top: 8px;
  }
  .popup.success-popup .popup-head .title {
    padding-left: 52px;
  }
  .popup {
    right: -101%;
  }
  .top_panel .user .usermenu ul li a {
    font-size: 18px;
  }
  .top_panel .user .usermenu {
    width: 220px;
  }
  .popup-head .link {
    bottom: 6px;
  }
}
@media screen and (max-width: 480px) {
  .top_panel .center .menu ul li a {
    font-size: 22px;
  }
  .top_panel .center .menu ul li a {
    line-height: 60px;
  }
  .page.static-page .left_content .description {
    font-size: 18px;
  }
  .page.static-page .left_content p:not([class]) {
    font-size: 16px;
  }
  .page.static-page .left_content ul li,
  .page.static-page .left_content ol li {
    font-size: 16px;
  }
  .page.static-page .left_content ul li:before {
    width: 6px;
    height: 6px;
  }
  .page.static-page .left_content h2,
  .page.static-page .left_content h3,
  .page.static-page .left_content h4,
  .page.static-page .left_content h5,
  .page.static-page .left_content h6 {
    margin: 20px 0 5px;
  }
  .page.static-page .page_head {
    margin-bottom: 8px;
  }
  .popup .scroll .scroll_wrap {
    padding-top: 65px;
  }
  .popup .close {
    width: 40px;
    height: 40px;
    top: 10px;
  }
  .popup .select-userpic {
    top: 10px;
  }
  .popup .select-userpic .userpic {
    width: 40px;
    height: 40px;
    line-height: 40px;
  }
  .popup .select-userpic .select label {
    line-height: 40px;
  }
  .popup .close {
    right: 18px;
  }
  .search-form .date-col .day {
    font-size: 46px;
  }
  .search-form .label {
    font-size: 19px;
    margin-bottom: 10px;
  }
  .search-form .num-col {
    padding-top: 18px;
  }
  .search-form .destination-col .select_wrap {
    height: 50px;
  }
  .search-form .destination-col .select_wrap select {
    font-size: 19px;
  }
  .search-form .destination-col .label {
    margin-bottom: 10px;
  }
  .search-form .btn {
    margin-top: 24px;
  }
  .search-form .destination-col {
    margin-bottom: 25px;
  }
  .main_slider .slider_wrap .slide .slide_content .title_wrap .slide_title {
    font-size: 48px;
  }
  .popup.profile-setting .scroll .scroll_wrap {
    padding-top: 65px;
  }
}
@media screen and (max-width: 400px) {
  .main_slider .slider_wrap .slide .slide_content .buttons .btn {
    font-size: 16px;
    padding: 0 20px;
  }
  .main_slider .slider_wrap .slide .slide_content .buttons .link {
    font-size: 16px;
  }
  .destinations .section_content .scroll .destinations_item {
    width: 270px;
    margin-right: 13px;
    margin-bottom: 13px;
  }
  .search-form .num-col.last {
    width: 50%;
  }
  .story_item {
    margin-right: 13px;
  }
  .blog_item_top .sq_parent {
    padding-bottom: 80%;
  }
  .blog_item_top ._title {
    font-size: 21px;
  }
  .social-links .link {
    margin-right: 15px;
  }
  .footer_top .right .address {
    font-size: 17px;
  }
  .ui-widget.ui-widget-content {
    width: 296px;
  }
  .mobile .most_popular .section_content .tour-slider .tour_item {
    width: 270px;
    margin-right: 13px;
    margin-bottom: 13px;
  }
  .mobile .stories .section_content .stries_slider .story_item {
    margin-right: 13px;
    margin-bottom: 13px;
  }
  .subscribe_section .subscribe_block .right .input_wrap select {
    padding-left: 18px;
  }
  .subscribe_section .subscribe_block .right .input_wrap .input {
    padding: 0 18px;
  }
  .top_panel .center .menu .mobile_content .link {
    margin-right: 14px;
  }
  .pagination {
    display: flex;
    justify-content: space-between;
  }
}
@media screen and (max-width: 358px) {
  .pagination ul li a {
    width: 44px;
    height: 44px;
    line-height: 44px;
  }
  .pagination .arrow {
    width: 44px;
    height: 44px;
  }
}
@media screen and (max-width: 340px) {
  .main_slider .slider_wrap .slide .slide_content .title_wrap .slide_title {
    font-size: 41px;
  }
  .popup.book-now-popup .tour-info .col .date .day {
    font-size: 38px;
  }
  .os-ios .popup.book-now-popup .tour-info .col .date .day {
    line-height: 1;
    margin-bottom: 0;
  }
  .popup.book-now-popup .tour-info .col .date {
    padding-left: 60px;
    padding-top: 2px;
    margin-bottom: 10px;
  }
  .os-ios .popup.book-now-popup .tour-info .col .date {
    padding-top: 0;
  }
  .popup.book-now-popup .tour-info .col .label {
    font-size: 17px;
  }
  .popup.book-now-popup .tour-info {
    margin-top: 2px;
  }
}
@media screen and (max-height: 654px) {
  .modal_search .search-tour {
    display: block;
    padding: 0;
  }
  .modal_search {
    overflow: auto;
  }
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .shadow {
    display: none !important;
  }
  .tour_item:hover,
  .destinations_item:hover,
  .story_item:hover {
    -webkit-box-shadow: 0px 10px 15px 0px rgba(0, 0, 0, 0.37);
    -moz-box-shadow: 0px 10px 15px 0px rgba(0, 0, 0, 0.37);
    box-shadow: 0px 10px 15px 0px rgba(0, 0, 0, 0.37);
  }
  .blog_item:hover .blog_item_top {
    -webkit-box-shadow: 0px 10px 15px 0px rgba(0, 0, 0, 0.37);
    -moz-box-shadow: 0px 10px 15px 0px rgba(0, 0, 0, 0.37);
    box-shadow: 0px 10px 15px 0px rgba(0, 0, 0, 0.37);
    transition: all .2s linear;
  }
  .top_panel .currency select {
    font-size: 13px;
    padding: 0 5px;
  }
  .search-form .num-col .val {
    padding-top: 0;
  }
  .social-links .link:after {
    display: none;
  }
  .travel-list .posts .item:hover .item_left .image {
    -webkit-box-shadow: 0px 10px 15px 0px rgba(0, 0, 0, 0.37);
    -moz-box-shadow: 0px 10px 15px 0px rgba(0, 0, 0, 0.37);
    box-shadow: 0px 10px 15px 0px rgba(0, 0, 0, 0.37);
    transition: all .2s linear;
  }
  .sidebar .search-form .btn {
    border-radius: 0;
  }
  .subscribe_section .subscribe_block .right .input_wrap.select_wrap:before {
    right: 28px;
  }
}
/*Import "Story page" styles*/
/*------------------------------------------------------------------
[Table of contents]

1. Page styles
2. Media queries
-------------------------------------------------------------------*/
/*----------------------------------------------------------------------------------
1. Page styles
-----------------------------------------------------------------------------------*/
.stories_page {
  position: fixed;
  height: 100vh;
  padding-top: 160px;
  padding-bottom: 50px;
  padding-left: 50px;
  padding-right: 50px;
}
.stories_page .left_btn {
  background: #D03000;
  width: auto;
  height: 50px;
  line-height: 50px;
  padding: 0 14px;
  font-size: 21px;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  position: absolute;
  z-index: 2;
  bottom: 50px;
  left: 50px;
}
.stories_page .left_btn:hover {
  background: #FF3B00;
  -webkit-box-shadow: 0px 3px 15px 0px rgba(255, 59, 0, 0.78);
  -moz-box-shadow: 0px 3px 15px 0px rgba(255, 59, 0, 0.78);
  box-shadow: 0px 3px 15px 0px rgba(255, 59, 0, 0.78);
}
.stories_page .right_btn {
  background: rgba(255, 255, 255, 0.28);
  width: auto;
  height: 50px;
  line-height: 50px;
  padding: 0 14px;
  font-size: 21px;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  position: absolute;
  z-index: 2;
  bottom: 50px;
  right: 50px;
}
.stories_page .right_btn:hover {
  background: #fff;
  color: #222;
  -webkit-box-shadow: 0px 3px 15px 0px rgba(255, 255, 255, 0.78);
  -moz-box-shadow: 0px 3px 15px 0px rgba(255, 255, 255, 0.78);
  box-shadow: 0px 3px 15px 0px rgba(255, 255, 255, 0.78);
}
.stories_page .stories_bg {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: auto;
  transform: scale(1.2);
  overflow: hidden;
}
.stories_page .stories_bg img {
  min-height: 100%;
  min-width: 100%;
  display: none;
  position: absolute;
  top: 0;
  left: 0;
}
.stories_page .stories_bg img:last-child {
  display: block;
}
.stories_page_wrap {
  height: 100%;
  z-index: 1;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
.stories_page_wrap .stories_box {
  height: 100%;
  width: 520px;
  padding-bottom: 55px;
  position: relative;
}
.stories_page_wrap .stories_box .dots {
  height: 10px;
  text-align: center;
  margin-top: 6px;
  display: none;
}
.stories_page_wrap .stories_box .dots ul {
  display: inline-block;
  float: none;
  width: auto;
  vertical-align: top;
}
.stories_page_wrap .stories_box .dots ul li {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.41);
  margin: 0 7px;
  transition: all .2s linear;
}
.stories_page_wrap .stories_box .dots ul li.active {
  background: #fff;
  transition: all .2s linear;
}
.stories_page_wrap .stories_box .arrows .arrow {
  width: 44px;
  height: 44px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.42);
  position: absolute;
  transition: all .2s linear;
  top: 50%;
  margin-top: -77px;
}
.stories_page_wrap .stories_box .arrows .arrow.disabled {
  opacity: .5;
}
.stories_page_wrap .stories_box .arrows .arrow.disabled:hover {
  background: rgba(255, 255, 255, 0.42);
  cursor: default;
}
.stories_page_wrap .stories_box .arrows .arrow.disabled:hover:before {
  opacity: 1;
}
.stories_page_wrap .stories_box .arrows .arrow.disabled:hover:after {
  opacity: 0;
}
.stories_page_wrap .stories_box .arrows .arrow:before {
  width: 16px;
  height: 12px;
  /* background: url(./img/left-arrow.svg) center center no-repeat; */
  background-size: contain;
  position: absolute;
  content: '';
  top: 50%;
  left: 50%;
  margin-top: -6px;
  margin-left: -8px;
  opacity: 1;
  transition: all .2s linear;
}
.stories_page_wrap .stories_box .arrows .arrow:after {
  width: 16px;
  height: 12px;
  /* background: url(../img/right-arrow.svg) center center no-repeat; */
  background-size: contain;
  position: absolute;
  content: '';
  top: 50%;
  left: 50%;
  margin-top: -6px;
  margin-left: -8px;
  opacity: 0;
  transition: all .2s linear;
  transform: rotate(180deg);
}
.stories_page_wrap .stories_box .arrows .arrow.prev {
  left: -64px;
}
.stories_page_wrap .stories_box .arrows .arrow.next {
  right: -64px;
  margin-left: 18px;
  transform: rotate(180deg);
}
.stories_page_wrap .stories_box .arrows .arrow:hover {
  background: #fff;
  transition: all .2s linear;
  cursor: pointer;
}
.stories_page_wrap .stories_box .arrows .arrow:hover:before {
  opacity: 0;
  transition: all .2s linear;
}
.stories_page_wrap .stories_box .arrows .arrow:hover:after {
  opacity: 1;
  transition: all .2s linear;
}
.stories_page_wrap .stories_box .items {
  height: 100%;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
}
.stories_page_wrap .stories_box .items .item {
  position: absolute;
  width: 100%;
  height: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 120px 40px 75px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  transition: all .3s linear;
  transform: scale(0.9);
  will-change: transform;
  border-radius: 10px;
  overflow: hidden;
}
.stories_page_wrap .stories_box .items .item.active {
  transform: none;
  transition: all .3s linear;
}
.stories_page_wrap .stories_box .items .item.seen {
  transform: translateX(-100%);
}
.stories_page_wrap .stories_box .items .item:before {
  background: -moz-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.75) 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.75) 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.75) 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00000000', endColorstr='#bf000000', GradientType=0);
  /* IE6-9 */
  height: 50%;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  content: '';
}
.stories_page_wrap .stories_box .items .item ._title {
  font-size: 33px;
  font-family: 'Prata', serif;
  color: #fff;
  line-height: 1.3;
  margin-bottom: 20px;
  position: relative;
  z-index: 1;
}
.stories_page_wrap .stories_box .items .item ._text {
  font-size: 21px;
  color: #fff;
  line-height: 1.3;
  position: relative;
  z-index: 1;
}
.stories_page_wrap .stories_box .items .item .tag {
  color: rgba(255, 255, 255, 0.9);
  font-size: 15px;
  height: 28px;
  background: #222;
  line-height: 28px;
  width: auto;
  padding: 0 14px;
  border-radius: 4px;
  margin-bottom: 18px;
}
.stories_page_wrap .stories_box .items .item ._info {
  position: absolute;
  top: 0;
  height: 70px;
  left: 0;
  top: 24px;
  padding-left: 40px;
  padding-right: 125px;
}
.stories_page_wrap .stories_box .items .item ._info .country {
  font-size: 16px;
  color: #fff;
  height: inherit;
  display: flex;
  align-items: center;
}
.stories_page_wrap .stories_box .items .item ._info .country span {
  padding-left: 12px;
  position: relative;
}
.stories_page_wrap .stories_box .items .item ._info .country span:before {
  width: 6px;
  height: 6px;
  background: #D03000;
  border-radius: 50%;
  content: '';
  position: absolute;
  left: 0;
  top: 8px;
}
.stories_page_wrap .stories_box .items .item ._info .flag {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  position: absolute;
  overflow: hidden;
  top: 0;
  right: 20px;
}
.stories_page_wrap .stories_box .items .item ._info .flag img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
}
.stories_page_wrap .stories_box .story_info {
  position: absolute;
  height: 42px;
  bottom: 0;
  left: 0;
  padding-right: 100px;
}
.stories_page_wrap .stories_box .story_info .author {
  position: relative;
  padding-left: 52px;
}
.stories_page_wrap .stories_box .story_info .author .userpic {
  width: 42px;
  height: 42px;
  border-radius: 50%;
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
}
.stories_page_wrap .stories_box .story_info .author .userpic img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.stories_page_wrap .stories_box .story_info .author .name {
  font-size: 15px;
  color: rgba(255, 255, 255, 0.61);
  height: 42px;
  display: flex;
  align-items: center;
}
.stories_page_wrap .stories_box .story_info .counter {
  font-size: 28px;
  font-family: 'Prata', serif;
  color: #fff;
  width: 100px;
  line-height: 42px;
  position: absolute;
  text-align: right;
  right: 0;
  top: 0;
}
.stories_page_wrap .stories_box .story_info .counter .all {
  font-size: 20px;
}
.stories_page .orientation-message {
  display: none;
}
/*----------------------------------------------------------------------------------
2. Media queries
-----------------------------------------------------------------------------------*/
@media screen and (max-width: 2000px) {
  .stories_page_wrap .stories_box {
    width: 47vh;
    min-width: 400px;
  }
  .stories_page_wrap .stories_box .items .item {
    padding: 100px 35px 35px;
  }
  .stories_page_wrap .stories_box .items .item ._text {
    font-size: 19px;
  }
  .stories_page_wrap .stories_box .items .item ._title {
    font-size: 31px;
  }
  .stories_page_wrap .stories_box .items .item ._info {
    padding-left: 35px;
  }
  .stories_page {
    padding-top: 130px;
  }
}
@media screen and (max-width: 1500px) {
  .stories_page {
    padding-bottom: 26px;
    padding-left: 26px;
    padding-right: 26px;
  }
  .stories_page .left_btn {
    left: 26px;
    bottom: 26px;
    font-size: 18px;
  }
  .stories_page .right_btn {
    right: 26px;
    bottom: 26px;
    font-size: 18px;
  }
  .stories_page_wrap .stories_box .items .item ._title {
    font-size: 27px;
  }
  .stories_page_wrap .stories_box .items .item ._text {
    font-size: 18px;
  }
  .stories_page_wrap .stories_box .items .item ._info .flag {
    width: 60px;
    height: 60px;
  }
  .stories_page_wrap .stories_box .items .item ._info {
    height: 60px;
  }
  .stories_page {
    padding-top: 118px;
  }
}
@media screen and (max-width: 1200px) {
  .stories_page {
    padding-top: 92px;
  }
}
@media screen and (max-width: 740px) {
  .stories_page {
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
    padding-bottom: 0;
    height: auto;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
  }
  .stories_page_wrap .stories_box {
    width: 100%;
    padding-top: 80px;
    padding-bottom: 82px;
    min-width: inherit;
  }
  .stories_page_wrap .stories_box .items {
    border-radius: 0;
  }
  .stories_page_wrap .stories_box .items .item {
    border-radius: 0;
  }
  .stories_page .left_btn {
    height: 38px;
    line-height: 38px;
    width: 44%;
    left: 4%;
    font-size: 16px;
    bottom: 16px;
  }
  .stories_page .right_btn {
    height: 38px;
    line-height: 38px;
    width: 44%;
    right: 4%;
    font-size: 16px;
    bottom: 16px;
  }
  .stories_page_wrap .stories_box .story_info {
    display: none;
  }
  .stories_page_wrap .stories_box .dots {
    display: block;
    margin-top: 8px;
  }
  .stories_page_wrap .stories_box .arrows {
    display: none;
  }
  .stories_page_wrap .stories_box .arrows .arrow {
    width: 40%;
    height: 100%;
    top: 80px;
    bottom: 82px;
    margin-top: 0;
    z-index: 2;
    background: none;
    border-radius: 0;
    height: auto;
  }
  .stories_page_wrap .stories_box .arrows .arrow:hover {
    background: none;
  }
  .stories_page_wrap .stories_box .arrows .arrow.prev {
    left: 0;
  }
  .stories_page_wrap .stories_box .arrows .arrow.next {
    right: 0;
  }
  .stories_page_wrap .stories_box .arrows .arrow:before {
    display: none;
  }
  .stories_page_wrap .stories_box .arrows .arrow:after {
    display: none;
  }
}
@media screen and (max-width: 1040px) and (orientation: landscape) {
  .stories_page .orientation-message {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #000;
    z-index: 5;
    color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}
@media screen and (min-height: 460px) and (max-width: 1040px) {
  .stories_page_wrap .stories_box {
    padding-bottom: 92px;
  }
}
@media screen and (max-width: 480px) {
  .stories_page_wrap .stories_box .items .item {
    padding: 67px 16px 20px;
  }
  .stories_page_wrap .stories_box .items .item ._info {
    height: 48px;
    top: 10px;
    padding-left: 16px;
    padding-right: 84px;
  }
  .stories_page_wrap .stories_box .items .item ._info .flag {
    height: 48px;
    width: 48px;
    right: 16px;
  }
  .stories_page_wrap .stories_box .items .item ._text {
    font-size: 15px;
  }
  .stories_page_wrap .stories_box .items .item ._title {
    font-size: 23px;
    margin-bottom: 14px;
  }
  .stories_page_wrap .stories_box .items .item .tag {
    margin-bottom: 8px;
  }
}
@media screen and (max-height: 550px) and (orientation: landscape) {
  .stories_page {
    height: 550px;
  }
}
/*Import "Travel list page" styles*/
/*------------------------------------------------------------------
[Table of contents]

1. Page styles

1.1. Left sidebar
1.2. Right sidebar
1.3. Full width
1.4. Full width image header

2. Media queries
-------------------------------------------------------------------*/
/*----------------------------------------------------------------------------------
1. Page styles
-----------------------------------------------------------------------------------*/
.travel-list:not(.default) .page_head {
  margin-bottom: 50px;
}
.travel-list .pagination {
  text-align: left;
}
.travel-list .posts .item {
  margin-bottom: 38px;
  display: block;
  float: left;
  width: 100%;
}
.travel-list .posts .item:hover .item_left .image .shadow {
  opacity: 1;
  transition: all .2s linear;
}
.travel-list .posts .item:hover .item_right {
  background: #f5f5f5;
  transition: all .2s linear;
}
.travel-list .posts .item_left {
  max-width: 394px;
}
.travel-list .posts .item_left .image {
  height: 23em;
  background: #000;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 13px;
  position: relative;
}
.travel-list .posts .item_left .shadow {
  filter: blur(10px);
  -webkit-filter: blur(10px);
  -moz-filter: blur(10px);
  position: absolute;
  top: 10px;
  left: -6px;
  right: -6px;
  bottom: -10px;
  z-index: -1;
  width: auto;
  opacity: 0;
  transition: all 0.21s ease-out;
  will-change: opacity;
  transition: all .2s linear;
}
.travel-list .posts .item_right {
  background: rgba(245, 245, 245, 0.74);
  border-radius: 13px;
  min-height: 368px;
  max-width: 668px;
  float: right;
  padding: 34px 44px;
  position: relative;
  transition: all .2s linear;
}
.travel-list .posts .item_right .country {
  font-size: 16px;
  color: #222;
  padding-left: 12px;
  position: relative;
  margin-bottom: 18px;
}
.travel-list .posts .item_right .country:before {
  width: 6px;
  height: 6px;
  background: #D03000;
  border-radius: 50%;
  content: '';
  position: absolute;
  left: 0;
  top: 8px;
}
.travel-list .posts .item_right .rating-stars {
  width: auto;
  margin-right: 12px;
}
.travel-list .posts .item_right .rating-stars .star {
  position: relative;
  width: 24px;
  height: 28px;
}
.travel-list .posts .item_right .rating-stars .star:before {
  width: 18px;
  height: 16px;
  /* background: url(../img/star-dark.svg) center center no-repeat; */
  background-size: contain;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -8px;
  margin-left: -9px;
  content: '';
}
.travel-list .posts .item_right .rating-stars .star.filled:before {
  display: none;
}
.travel-list .posts .item_right .rating-stars .star.filled:after {
  width: 18px;
  height: 16px;
  /* background: url(../img/star-active.svg) center center no-repeat; */
  background-size: contain;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -8px;
  margin-left: -9px;
  content: '';
}
.travel-list .posts .item_right .item_title {
  font-size: 33px;
  font-family: 'Prata', serif;
  max-height: 94px;
  overflow: hidden;
  margin-bottom: 14px;
}
.travel-list .posts .item_right .item_text {
  color: #919193;
  font-size: 18px;
  max-height: 74px;
  overflow: hidden;
}
.travel-list .posts .item_right .info {
  position: absolute;
  left: 0;
  bottom: 0;
  padding: 0 44px 30px;
}
.travel-list .posts .item_right .info .days {
  font-size: 21px;
  color: rgba(0, 0, 0, 0.53);
  width: auto;
  margin-right: 8px;
  line-height: 40px;
}
.travel-list .posts .item_right .info .cost {
  font-size: 21px;
  font-weight: 700;
  color: #000;
  width: auto;
  line-height: 40px;
}
.travel-list .posts .item_right .info .sale {
  background: #FF3B00;
  height: 40px;
  line-height: 40px;
  width: auto;
  display: inline-block;
  float: none;
  border-radius: 5px;
  font-size: 21px;
  color: #fff;
  font-weight: 600;
  padding: 0 12px;
  margin: 0 12px;
}
.travel-list .posts .item_right .add_bookmark {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background: #ffffff;
  position: absolute;
  right: 30px;
  top: 18px;
}
.travel-list .posts .item_right .add_bookmark:hover {
  cursor: pointer;
}
.travel-list .posts .item_right .add_bookmark:hover * {
  cursor: pointer;
}
.travel-list .posts .item_right .add_bookmark .not-added {
  width: 18px;
  height: 22px;
  /* background: url(../img/bookmark.svg) center center no-repeat; */
  background-size: contain;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -11px;
  margin-left: -9px;
  content: '';
  opacity: 1;
}
.travel-list .posts .item_right .add_bookmark .is-added {
  width: 18px;
  height: 22px;
  /* background: url(../img/bookmark.svg) center center no-repeat; */
  background-size: contain;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -11px;
  margin-left: -9px;
  content: '';
  z-index: 2;
}
.travel-list .posts .item_right .add_bookmark i.not-added {
  display: block;
  position: relative;
  z-index: 1;
}
.travel-list .posts .item_right .add_bookmark i.is-added {
  display: none;
  position: relative;
}
.travel-list .posts .item_right .add_bookmark .fav-overlay {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background: #D03000;
  transform: scale(0);
  transition: all .4s;
  z-index: 1;
}
.travel-list .posts .item_right .add_bookmark.added .fav-overlay {
  transform: scale(1);
}
.travel-list .posts .item_right .add_bookmark.added i.not-added {
  display: none;
}
.travel-list .posts .item_right .add_bookmark.added i.is-added {
  display: block;
}
.travel-list.left-sidebar .car_sidebar {
  float: left;
}
.travel-list.left-sidebar .left_content {
  float: right;
}
.travel-list.left-sidebar .search-tour {
  display: none;
}
.travel-list.left-sidebar .car_sidebar .search-tour {
  display: block;
}
.travel-list.right-sidebar .car_sidebar {
  float: right;
}
.travel-list.right-sidebar .left_content {
  float: left;
}
.travel-list.right-sidebar .search-tour {
  display: none;
}
.travel-list.right-sidebar .car_sidebar .search-tour {
  display: block;
}
.travel-list.full-width .pagination {
  text-align: center;
}
.travel-list.full-width-image-header .page_head .title {
  color: #fff;
}
.travel-list.full-width-image-header .page_head .subtitle {
  color: rgba(255, 255, 255, 0.55);
}
.image_header {
  height: 526px;
  position: absolute;
  top: 0;
  left: 0;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
}
.image_header:before {
  background: #000;
  opacity: .45;
  position: absolute;
  content: '';
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.car_sidebar .search-tour {
  padding: 0;
}
.car_sidebar .search-form {
  padding: 25px;
  height: auto;
}
.car_sidebar .search-form .label {
  font-size: 18px;
  margin-bottom: 10px;
}
.car_sidebar .search-form .destination-col {
  width: 100%;
  margin-right: 0;
  margin-bottom: 20px;
}
.car_sidebar .search-form .destination-col .label {
  margin-bottom: 10px;
}
.car_sidebar .search-form .destination-col .select_wrap select {
  font-size: 18px;
  padding-left: 15px;
}
.car_sidebar .search-form .date-col {
  width: 50%;
  margin-right: 0;
  border-right: 0;
  margin-bottom: 17px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.24);
  padding-bottom: 17px;
}
.car_sidebar .search-form .date-col .day {
  font-size: 42px;
}
.car_sidebar .search-form .date-col .date_div_right {
  margin-left: 0;
  clear: both;
  padding-top: 0;
}
.car_sidebar .search-form .date-col .date_div_right .month {
  font-size: 15px;
}
.car_sidebar .search-form .date-col .date_div_right .year {
  font-size: 15px;
}
.car_sidebar .search-form .num-col {
  width: 50%;
  border-right: 0;
}
.car_sidebar .search-form .num-col .val {
  font-size: 42px;
  padding-top: 0;
  margin-left: 4px;
  height: 58px;
}
.car_sidebar .search-form .num-col .buttons {
  padding-top: 0;
}
.car_sidebar .search-form .num-col .buttons button {
  width: 26px;
  height: 26px;
}
.car_sidebar .search-form .num-col .buttons button.plus {
  margin-bottom: 0;
}
.car_sidebar .search-form .num-col.last {
  padding-left: 0;
}
.car_sidebar .search-form .btn {
  position: relative;
  margin-left: 0;
  right: 0;
  padding-left: 64px;
  width: auto;
  background: none;
  margin-top: 12px;
}
.car_sidebar .search-form .btn:before {
  z-index: 1;
  margin-left: 19px;
  left: 0;
}
.car_sidebar .search-form .btn:after {
  width: 54px;
  height: 54px;
  background: #d03000;
  border-radius: 50%;
  content: '';
  left: 0;
  position: absolute;
  top: 0;
}
.car_sidebar .search-form .btn span {
  display: block;
  font-size: 18px;
  color: #fff;
}
.car_sidebar ._block {
  margin-top: 30px;
}
.car_sidebar ._block .block_title {
  font-size: 30px;
  font-family: 'Prata', serif;
  margin-bottom: 26px;
}
.car_sidebar ._block .stories {
  border-radius: 13px;
  overflow: hidden;
  position: relative;
  padding: 0;
}
.car_sidebar ._block .stories .arrows {
  position: absolute;
  z-index: 2;
  top: 17px;
  right: 24px;
  width: auto;
}
.car_sidebar ._block .stories .arrows .arrow {
  width: 44px;
  height: 44px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.42);
  position: relative;
  transition: all .2s linear;
  z-index: 1;
}
.car_sidebar ._block .stories .arrows .arrow:before {
  width: 16px;
  height: 12px;
  /* background: url(./img/left-arrow.svg) center center no-repeat; */
  background-size: contain;
  position: absolute;
  content: '';
  top: 50%;
  left: 50%;
  margin-top: -6px;
  margin-left: -8px;
  opacity: 1;
  transition: all .2s linear;
}
.car_sidebar ._block .stories .arrows .arrow:after {
  width: 16px;
  height: 12px;
  /* background: url(../img/right-arrow.svg) center center no-repeat; */
  background-size: contain;
  position: absolute;
  content: '';
  top: 50%;
  left: 50%;
  margin-top: -6px;
  margin-left: -8px;
  opacity: 0;
  transition: all .2s linear;
  transform: rotate(180deg);
}
.car_sidebar ._block .stories .arrows .arrow.next {
  margin-left: 18px;
  transform: rotate(180deg);
}
.car_sidebar ._block .stories .arrows .arrow:hover {
  background: #fff;
  transition: all .2s linear;
  cursor: pointer;
}
.car_sidebar ._block .stories .arrows .arrow:hover:before {
  opacity: 0;
  transition: all .2s linear;
}
.car_sidebar ._block .stories .arrows .arrow:hover:after {
  opacity: 1;
  transition: all .2s linear;
}
.car_sidebar ._block .stories .items .item {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  height: 26em;
  border-radius: 13px;
  overflow: hidden;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: column;
  text-align: center;
  padding-bottom: 38px;
  position: relative;
}
.car_sidebar ._block .stories .items .item:before {
  background: -moz-linear-gradient(top, rgba(0, 0, 0, 0) 0%, #000000 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 0%, #000000 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, #000000 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00000000', endColorstr='#000000', GradientType=0);
  /* IE6-9 */
  opacity: .74;
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
.car_sidebar ._block .stories .items .item .flag {
  display: inline-block;
  float: none;
  margin-bottom: 10px;
  position: relative;
  z-index: 1;
  width: 70px;
  border-radius: 50%;
  overflow: hidden;
  height: 70px;
}
.car_sidebar ._block .stories .items .item .flag img {
  height: 100%;
  width: auto;
  object-fit: cover;
}
.car_sidebar ._block .stories .items .item ._title {
  font-size: 33px;
  font-family: 'Prata', serif;
  color: #fff;
  position: relative;
  z-index: 1;
}
.car_sidebar ._block .popular .item {
  border-bottom: 1px solid #DFDFDF;
  padding-bottom: 28px;
  margin-bottom: 28px;
  display: block;
  float: left;
  width: 100%;
}
.car_sidebar ._block .popular .item:hover .item_top ._title {
  color: #FF3B00;
  transition: all .2s linear;
}
.car_sidebar ._block .popular .item:last-child {
  padding-bottom: 0;
  margin-bottom: 0;
  border-bottom: 0;
}
.car_sidebar ._block .popular .item_top {
  position: relative;
  padding-left: 130px;
  min-height: 102px;
}
.car_sidebar ._block .popular .item_top .img {
  width: 102px;
  height: 102px;
  position: absolute;
  left: 0;
  top: 0;
  border-radius: 10px;
  overflow: hidden;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}
.car_sidebar ._block .popular .item_top ._title {
  font-size: 21px;
  color: #222;
  /* min-height: 102px; */
  font-family: 'Prata', serif;
  display: flex;
  /* align-items: center; */
  transition: all .2s linear;
}
.car_sidebar ._block .popular .item_bottom {
  margin-top: 18px;
}
.car_sidebar ._block .popular .item_bottom .days {
  color: rgba(0, 0, 0, 0.53);
  font-size: 18px;
  display: block;
  float: left;
  line-height: 20px;
  width: auto;
}
.car_sidebar ._block .popular .item_bottom .sale {
  color: #FF3B00;
  font-size: 18px;
  font-weight: 600;
  display: block;
  float: left;
  line-height: 20px;
  width: auto;
  margin-left: 4px;
}
.car_sidebar ._block .popular .item_bottom .cost {
  font-size: 18px;
  color: #222;
  font-weight: 600;
  display: block;
  float: left;
  line-height: 20px;
  width: auto;
  margin-left: 7px;
}
/*----------------------------------------------------------------------------------
2. Media queries
-----------------------------------------------------------------------------------*/
@media screen and (max-width: 1520px) {
  .page .left_content {
    max-width: 950px;
  }
  .travel-list .posts .item_right {
    max-width: 530px;
  }
  .travel-list .posts .item_left {
    max-width: 350px;
  }
  .travel-list .posts .item_left .image {
    height: 21em;
  }
  .travel-list .posts .item_right {
    max-width: 570px;
    min-height: 336px;
  }
  .travel-list .posts .item_right .item_title {
    font-size: 26px;
    max-height: 74px;
  }
  .travel-list .posts .item_right .item_text {
    font-size: 16px;
    max-height: 68px;
  }
  .travel-list .posts .item_right .info .days {
    font-size: 18px;
  }
  .travel-list .posts .item_right .info .cost {
    font-size: 18px;
  }
  .travel-list .posts .item_right .info {
    padding-bottom: 20px;
  }
  .travel-list .posts .item_right .info .sale {
    font-size: 18px;
    line-height: 34px;
    height: 34px;
  }
}
@media screen and (max-width: 1300px) {
  .page .left_content {
    max-width: 840px;
  }
  .travel-list .posts .item_right {
    max-width: 464px;
  }
}
@media screen and (max-width: 1200px) {
  .page .left_content {
    max-width: 610px;
  }
  .travel-list .posts .item_left {
    max-width: 100%;
  }
  .travel-list .posts .item_right {
    max-width: 100%;
    margin-top: 10px;
  }
}
@media screen and (max-width: 1000px) {
  .page .left_content {
    max-width: 58%;
  }
  .page .right_content {
    max-width: 40%;
  }
  .search-form .num-col .val {
    width: 52px;
  }
  .travel-list .posts .item_right {
    padding-left: 26px;
    padding-right: 26px;
    padding-bottom: 80px;
  }
  .travel-list .posts .item_right .info {
    padding-left: 26px;
    padding-right: 26px;
  }
  .travel-list .posts .item_right .item_title {
    max-height: inherit;
    overflow: visible;
  }
  .travel-list .posts .item_right .item_text {
    max-height: inherit;
    overflow: visible;
  }
  .travel-list .pagination ul {
    margin: 0 3px;
  }
  .travel-list .posts .item_left .shadow {
    display: none;
  }
  .car_sidebar .search-form .num-col .buttons button.plus {
    margin-bottom: 3px;
  }
  .car_sidebar .search-form .num-col {
    padding-top: 0;
  }
  .car_sidebar .search-form .btn {
    margin-top: 30px;
  }
  .car_sidebar .search-form {
    display: none;
  }
  .travel-list.left-sidebar .page_head,
  .travel-list.right-sidebar .page_head {
    margin-bottom: 0;
  }
  .travel-list.left-sidebar .sidebar {
    max-width: 100%;
  }
  .sidebar ._block .stories .items .item {
    height: 60vw;
  }
  .travel-list.left-sidebar .search-tour,
  .travel-list.right-sidebar .search-tour {
    display: block;
  }
}
@media screen and (max-width: 760px) {
  .page .left_content {
    max-width: 100%;
  }
  .page .right_content {
    max-width: 100%;
  }
  .page .right_content {
    margin-top: 40px;
  }
  .travel-list .posts .item_left .image {
    height: 50vw;
  }
  .car_sidebar ._block .stories .items .item {
    height: 110vw;
  }
  .travel-list .posts .item:last-child {
    margin-bottom: 0;
  }
  .page_body {
    overflow: hidden;
  }
  .car_sidebar .search-form {
    position: relative;
    padding-left: 0;
    padding-right: 0;
  }
  .car_sidebar .search-form:before {
    width: 5vw;
    background: #0030d0;
    position: absolute;
    content: '';
    top: 0;
    bottom: 0;
    left: 100%;
  }
  .car_sidebar .search-form:after {
    width: 5vw;
    background: #0030d0;
    position: absolute;
    content: '';
    top: 0;
    bottom: 0;
    right: 100%;
  }
  .travel-list .page_head {
    margin-bottom: 26px;
  }
  .travel-list .posts .item_right {
    padding-left: 14px;
    padding-right: 14px;
  }
  .travel-list .posts .item_right .info {
    padding-left: 14px;
    padding-right: 14px;
  }
  .travel-list .posts .item {
    margin-bottom: 24px;
  }
  .image_header {
    height: 430px;
  }
  .travel-list.left-sidebar .car_sidebar ._block:nth-child(2),
  .travel-list.right-sidebar .car_sidebar ._block:nth-child(2) {
    margin-top: 0;
  }
  .travel-list .pagination {
    text-align: center;
  }
}
@media screen and (max-width: 640px) {
  .travel-list .posts .item_right .add_bookmark {
    width: 50px;
    height: 50px;
    right: 14px;
  }
  .travel-list .posts .item_right .add_bookmark .fav-overlay {
    width: 50px;
    height: 50px;
  }
  .travel-list .posts .item_right .add_bookmark .is-added {
    width: 14px;
    margin-left: -7px;
  }
  .travel-list .posts .item_right .add_bookmark .not-added {
    width: 14px;
    margin-left: -7px;
  }
  .travel-list .posts .item_right .info .days {
    font-size: 15px;
  }
  .travel-list .posts .item_right .info .cost {
    font-size: 15px;
  }
  .travel-list .posts .item_right .info .sale {
    font-size: 15px;
    margin: 0 4px;
  }
  .travel-list.full-width-image-header .page_head {
    margin-bottom: 25px;
  }
}
@media screen and (max-width: 480px) {
  .travel-list.full-width .pagination {
    display: flex;
    justify-content: space-between;
  }
}
/*Import "Tour page" styles*/
/*------------------------------------------------------------------
[Table of contents]

1. Page styles
1.1. Left sidebar

2. Comments section
3. Write comment section
4. Media queries
-------------------------------------------------------------------*/
/*----------------------------------------------------------------------------------
1. Page styles
-----------------------------------------------------------------------------------*/
.tour_page .breadcrumbs {
  position: relative;
  z-index: 1;
  color: rgba(255, 255, 255, 0.58);
}
.tour_page_head {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  padding-bottom: 115px;
}
.tour_page_head:before {
  opacity: .3;
  background: #000;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.tour_page_head .top-info {
  position: relative;
  padding-right: 222px;
  margin-bottom: 44px;
}
.tour_page_head .top-info .country {
  font-size: 25px;
  color: #fff;
  padding-left: 17px;
  position: relative;
  max-width: 860px;
  margin-bottom: 10px;
}
.tour_page_head .top-info .country:before {
  width: 9px;
  height: 9px;
  background: #D03000;
  border-radius: 50%;
  content: '';
  position: absolute;
  left: 0;
  top: 12px;
}
.tour_page_head .top-info .tour_title {
  font-size: 48px;
  color: #fff;
  font-family: 'Prata', serif;
  max-width: 860px;
  line-height: 1.2;
}
.tour_page_head .top-info .controls {
  position: absolute;
  z-index: 2;
  bottom: 14px;
  right: 0;
  width: auto;
}
.tour_page_head .top-info .controls .arrows {
  width: auto;
}
.tour_page_head .top-info .controls .arrows .arrow {
  width: 44px;
  height: 44px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.42);
  position: relative;
  transition: all .2s linear;
}
.tour_page_head .top-info .controls .arrows .arrow:before {
  width: 16px;
  height: 12px;
  /* background: url(./img/left-arrow.svg) center center no-repeat; */
  background-size: contain;
  position: absolute;
  content: '';
  top: 50%;
  left: 50%;
  margin-top: -6px;
  margin-left: -8px;
  opacity: 1;
  transition: all .2s linear;
}
.tour_page_head .top-info .controls .arrows .arrow:after {
  width: 16px;
  height: 12px;
  /* background: url(../img/right-arrow.svg) center center no-repeat; */
  background-size: contain;
  position: absolute;
  content: '';
  top: 50%;
  left: 50%;
  margin-top: -6px;
  margin-left: -8px;
  opacity: 0;
  transition: all .2s linear;
  transform: rotate(180deg);
}
.tour_page_head .top-info .controls .arrows .arrow.next {
  margin-left: 18px;
  transform: rotate(180deg);
}
.tour_page_head .top-info .controls .arrows .arrow:hover {
  background: #fff;
  transition: all .2s linear;
  cursor: pointer;
}
.tour_page_head .top-info .controls .arrows .arrow:hover:before {
  opacity: 0;
  transition: all .2s linear;
}
.tour_page_head .top-info .controls .arrows .arrow:hover:after {
  opacity: 1;
  transition: all .2s linear;
}
.tour_page_head .top-info .controls .add_bookmark {
  width: 44px;
  height: 44px;
  border-radius: 50%;
  background: #ffffff;
  position: relative;
  margin-right: 44px;
}
.tour_page_head .top-info .controls .add_bookmark:hover {
  cursor: pointer;
}
.tour_page_head .top-info .controls .add_bookmark:hover * {
  cursor: pointer;
}
.tour_page_head .top-info .controls .add_bookmark .not-added {
  width: 18px;
  height: 18px;
  /* background: url(../img/bookmark.svg) center center no-repeat; */
  background-size: contain;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -9px;
  margin-left: -9px;
  content: '';
  opacity: 1;
}
.tour_page_head .top-info .controls .add_bookmark .is-added {
  width: 18px;
  height: 18px;
  /* background: url(../img/bookmark.svg) center center no-repeat; */
  background-size: contain;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -9px;
  margin-left: -9px;
  content: '';
  z-index: 2;
}
.tour_page_head .top-info .controls .add_bookmark i.not-added {
  display: block;
  position: relative;
  z-index: 1;
}
.tour_page_head .top-info .controls .add_bookmark i.is-added {
  display: none;
  position: relative;
}
.tour_page_head .top-info .controls .add_bookmark .fav-overlay {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 44px;
  height: 44px;
  border-radius: 50%;
  background: #D03000;
  transform: scale(0);
  transition: all .4s;
  z-index: 1;
}
.tour_page_head .top-info .controls .add_bookmark.added .fav-overlay {
  transform: scale(1);
}
.tour_page_head .top-info .controls .add_bookmark.added i.not-added {
  display: none;
}
.tour_page_head .top-info .controls .add_bookmark.added i.is-added {
  display: block;
}
.tour_page_head .slider .slide {
  width: 456px;
  height: 302px;
  border-radius: 15px;
  overflow: hidden;
  margin-right: 36px;
  display: block;
  float: left;
}
.tour_page_head .slider .slide img {
  display: block;
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.tour_page_head .slider .slick-list {
  overflow: visible;
}
.tour_page_head .slider .slick-track {
  transition: transform .5s;
  will-change: transform;
}
.tour_page_head .slider .slick-slide {
  transition: all .5s;
}
.tour_page_head .bottom-info {
  margin-top: 100px;
}
.tour_page_head .bottom-info .bottom-info-left {
  max-width: 1020px;
}
.tour_page_head .bottom-info .bottom-info-left .search-tour {
  padding: 0;
}
.tour_page_head .bottom-info .bottom-info-left .search-tour .search-form {
  background: none;
  padding: 0;
  height: auto;
}
.tour_page_head .bottom-info .bottom-info-left .search-tour .search-form .num-col .buttons button:hover {
  background: #464646;
}
.tour_page_head .bottom-info .bottom-info-right {
  max-width: 300px;
  float: right;
}
.tour_page_head .bottom-info .bottom-info-right .info {
  margin-bottom: 20px;
}
.tour_page_head .bottom-info .bottom-info-right .info .days {
  color: rgba(255, 255, 255, 0.53);
  font-size: 24px;
  width: auto;
}
.tour_page_head .bottom-info .bottom-info-right .info .cost {
  font-size: 24px;
  color: #fff;
  font-weight: 600;
  width: auto;
  margin-left: 8px;
}
.tour_page_head .bottom-info .bottom-info-right .btn {
  height: 50px;
  background: #D03000;
  width: auto;
  padding: 0 32px;
  border-radius: 6px;
  font-size: 21px;
  color: #fff;
}
.tour_page_head .bottom-info .bottom-info-right .rating {
  margin-top: 16px;
}
.tour_page_head .bottom-info .bottom-info-right .rating .rating-stars {
  width: auto;
  margin-right: 12px;
}
.tour_page_head .bottom-info .bottom-info-right .rating .rating-stars .star {
  position: relative;
  width: 24px;
  height: 28px;
}
.tour_page_head .bottom-info .bottom-info-right .rating .rating-stars .star:before {
  width: 18px;
  height: 16px;
  /* background: url(../img/star.svg) center center no-repeat; */
  background-size: contain;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -8px;
  margin-left: -9px;
  content: '';
  opacity: .41;
}
.tour_page_head .bottom-info .bottom-info-right .rating .rating-stars .star.filled:before {
  display: none;
}
.tour_page_head .bottom-info .bottom-info-right .rating .rating-stars .star.filled:after {
  width: 18px;
  height: 16px;
  /* background: url(../img/star.svg) center center no-repeat; */
  background-size: contain;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -8px;
  margin-left: -9px;
  content: '';
}
.tour_page_head .bottom-info .bottom-info-right .rating .rating-text {
  font-size: 18px;
  color: rgba(255, 255, 255, 0.53);
  line-height: 28px;
  width: auto;
}
.tour_page_body {
  padding-top: 70px;
  padding-bottom: 50px;
}
.tour_page_body .wrap_float {
  position: relative;
}
.tour_page_body .left_content {
  max-width: 1090px;
}
.tour_page_body .left_content .title {
  font-size: 38px;
  margin-bottom: 22px;
}
.tour_page_body .left_content .description {
  color: rgba(74, 74, 74, 0.58);
  font-size: 18px;
}
.tour_page_body .left_content .content-block {
  margin-bottom: 68px;
}
.tour_page_body .left_content .programm {
  margin-top: 40px;
}
.tour_page_body .left_content .programm .day_item-head {
  border-bottom: 1px solid #DFDFDF;
  position: relative;
  padding: 10px 0;
  padding-left: 345px;
  padding-right: 60px;
}
.tour_page_body .left_content .programm .day_item-head:hover {
  cursor: pointer;
}
.tour_page_body .left_content .programm .day_item-head:hover * {
  cursor: pointer;
}
.tour_page_body .left_content .programm .day_item-head.active {
  transition: all .2s linear;
}
.tour_page_body .left_content .programm .day_item-head.active .element {
  background: #E9E9E9;
}
.tour_page_body .left_content .programm .day_item-head.active .element:before {
  background: #222;
  transition: all .2s linear;
}
.tour_page_body .left_content .programm .day_item-head.active .element:after {
  background: #222;
  transform: none;
  transition: all .2s linear;
}
.tour_page_body .left_content .programm .day_item-head .preview {
  position: absolute;
  top: 50%;
  margin-top: -30px;
  left: 0;
  height: 60px;
  width: auto;
}
.tour_page_body .left_content .programm .day_item-head .preview .image {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 20px;
}
.tour_page_body .left_content .programm .day_item-head .preview .image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center center;
  display: block;
}
.tour_page_body .left_content .programm .day_item-head .preview .p {
  font-size: 24px;
  font-weight: 600;
  color: #222;
  width: auto;
  line-height: 60px;
}
.tour_page_body .left_content .programm .day_item-head ._title {
  font-size: 20px;
  font-weight: 600;
  color: #222;
  height: 60px;
  display: table-cell;
  vertical-align: middle;
  float: none;
}
.tour_page_body .left_content .programm .day_item-head .element {
  width: 44px;
  height: 44px;
  border-radius: 50%;
  background: #C7C7C7;
  position: absolute;
  top: 50%;
  right: 0;
  margin-top: -22px;
  transition: all .2s linear;
}
.tour_page_body .left_content .programm .day_item-head .element:before {
  width: 12px;
  height: 2px;
  background: #fff;
  border-radius: 1px;
  content: '';
  position: absolute;
  top: 50%;
  margin-top: -1px;
  left: 50%;
  margin-left: -6px;
  transition: all .2s linear;
}
.tour_page_body .left_content .programm .day_item-head .element:after {
  width: 12px;
  height: 2px;
  background: #fff;
  border-radius: 1px;
  content: '';
  position: absolute;
  top: 50%;
  margin-top: -1px;
  left: 50%;
  margin-left: -6px;
  transform: rotate(90deg);
  transition: all .2s linear;
}
.tour_page_body .left_content .programm .day_item-body {
  display: none;
  padding-bottom: 40px;
  margin-top: 13px;
}
.tour_page_body .left_content .programm .day_item-body .text {
  font-size: 18px;
  color: #4A4A4A;
  margin: 14px 0;
}
.tour_page_body .left_content .programm .day_item-body .images {
  margin-top: 30px;
}
.tour_page_body .left_content .programm .day_item-body .images .item {
  width: 32%;
  margin-right: 2%;
  display: block;
  float: left;
}
.tour_page_body .left_content .programm .day_item-body .images .item span {
  font-size: 16px;
  color: #919193;
  margin-top: 10px;
  display: block;
  float: left;
}
.tour_page_body .left_content .programm .day_item-body .images .item:nth-child(3n) {
  margin-right: 0;
}
.tour_page_body .left_content .programm .day_item-body .images .item:nth-child(3n+1) {
  clear: both;
}
.tour_page_body .left_content .programm .day_item-body .images .img {
  height: 310px;
  border-radius: 10px;
  overflow: hidden;
}
.tour_page_body .left_content .programm .day_item-body .images .img img {
  display: block;
  object-fit: cover;
  object-position: center;
  width: 100%;
  height: 100%;
}
.tour_page_body .left_content .included {
  margin-bottom: 42px;
}
.tour_page_body .left_content .included ul li {
  position: relative;
  padding: 26px 0;
  padding-left: 62px;
  width: 50%;
  border-top: 1px solid #DFDFDF;
  padding-right: 40px;
}
.tour_page_body .left_content .included ul li:nth-child(odd) {
  clear: both;
}
.tour_page_body .left_content .included ul li:before {
  width: 44px;
  height: 44px;
  background: #2FB26B;
  border-radius: 50%;
  content: '';
  position: absolute;
  left: 0;
  top: 32px;
}
.tour_page_body .left_content .included ul li:after {
  /* background: url(../img/check-mark.svg) center center no-repeat; */
  background-size: contain;
  position: absolute;
  content: '';
  top: 48px;
  left: 14px;
  width: 16px;
  height: 12px;
}
.tour_page_body .left_content .included ul li:nth-child(1),
.tour_page_body .left_content .included ul li:nth-child(2) {
  border-top: 0;
  padding-top: 0;
}
.tour_page_body .left_content .included ul li:nth-child(1):before,
.tour_page_body .left_content .included ul li:nth-child(2):before {
  top: 6px;
}
.tour_page_body .left_content .included ul li:nth-child(1):after,
.tour_page_body .left_content .included ul li:nth-child(2):after {
  top: 22px;
}
.tour_page_body .left_content .included ul li .li_title {
  display: block;
  float: left;
  width: 100%;
  font-size: 20px;
  color: #222;
  margin-bottom: 4px;
}
.tour_page_body .left_content .included ul li .li_subtitle {
  color: rgba(74, 74, 74, 0.58);
  font-size: 18px;
  display: block;
  float: left;
  width: 100%;
}
.tour_page_body .left_content .location .map {
  height: 460px;
  overflow: hidden;
  border-radius: 10px;
  background: #eee;
  position: relative;
}
.tour_page_body .left_content .location .map iframe {
  width: 100%;
  height: 100%;
  border: 0;
  position: absolute;
  top: 0;
  left: 0;
}
.tour_page_body .left_content .stories {
  padding: 0;
  overflow: visible;
}
.tour_page_body .left_content .stories .title_wrap {
  position: relative;
  margin-bottom: 25px;
}
.tour_page_body .left_content .stories .title_wrap .title {
  margin-bottom: 0;
}
.tour_page_body .left_content .stories .title_wrap .link {
  font-size: 18px;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
  display: block;
  float: right;
  line-height: 44px;
}
.tour_page_body .left_content .stories .title_wrap .link a:hover {
  color: #FF3B00;
}
.tour_page_body .left_content .stories .story_item {
  width: 246px;
}
.tour_page_body .left_content .stories .story_item:last-child {
  margin-right: 0;
}
.tour_page_body .left_content .pagination {
  text-align: left;
}
.tour_page_body .left_content .pagination ul li a:hover {
  color: #003BFF;
}
.tour_page_body .left_content .pagination ul li a.active {
  background: #003BFF;
}
.tour_page_body .right_content {
  max-width: 310px;
  float: right;
  padding-top: 14px;
}
.tour_page.left-sidebar .car_sidebar {
  float: left;
}
.tour_page.left-sidebar .car_sidebar.fixed {
  right: auto;
  margin-right: 0;
  left: 50%;
  margin-left: -720px;
}
.tour_page.left-sidebar .tour_page_body .left_content {
  float: right;
}
.car_sidebar.fixed {
  position: fixed;
  right: 50%;
  margin-right: -720px;
  top: 0;
}
.car_sidebar.fixed.bottom {
  position: absolute;
  bottom: 0;
  right: 0;
  margin-right: 0;
  top: auto;
}
.car_sidebar .navigation {
  background: #151515;
  padding: 28px 38px;
  border-radius: 10px;
  margin-bottom: 20px;
}
.car_sidebar .navigation ul li {
  margin-bottom: 8px;
}
.car_sidebar .navigation ul li a {
  font-size: 20px;
  color: #fff;
  padding-left: 20px;
  position: relative;
  transition: all .2s linear;
}
.car_sidebar .navigation ul li a.active {
  color: #FF3B00;
  transition: all .2s linear;
}
.car_sidebar .navigation ul li a.active:before {
  background: #FF3B00;
  transition: all .2s linear;
}
.car_sidebar .navigation ul li a:before {
  width: 9px;
  height: 9px;
  background: none;
  border-radius: 50%;
  position: absolute;
  content: '';
  left: 0;
  top: 9px;
  transition: all .2s linear;
}
.car_sidebar .book-now {
  font-size: 20px;
  color: #fff;
  text-align: center;
  background: #D03000;
  border-radius: 10px;
  padding: 8px 0;
  margin-bottom: 20px;
  display: block;
  float: left;
  width: 100%;
}
.car_sidebar .book-now span {
  width: 100%;
  display: block;
  float: left;
}
.car_sidebar .book-now .cost {
  font-size: 24px;
  font-weight: 600;
}
.car_sidebar .add_bookmark {
  width: 100%;
  height: 60px;
  border-radius: 50%;
  position: absolute;
  left: 0;
  top: 0;
}
.car_sidebar .add_bookmark:before {
  width: 60px;
  background: rgba(214, 214, 214, 0.55);
  border-radius: 50%;
  top: 0;
  left: 0;
  content: '';
  height: 60px;
  position: absolute;
}
.car_sidebar .add_bookmark:hover {
  cursor: pointer;
}
.car_sidebar .add_bookmark:hover * {
  cursor: pointer;
}
.car_sidebar .add_bookmark .not-added {
  width: 22px;
  height: 22px;
  /* background: url(../img/bookmark.svg) center center no-repeat; */
  background-size: contain;
  position: absolute;
  top: 50%;
  left: 19px;
  margin-top: -11px;
  content: '';
  opacity: 1;
}
.car_sidebar .add_bookmark .is-added {
  width: 22px;
  height: 22px;
  /* background: url(../img/bookmark.svg) center center no-repeat; */
  background-size: contain;
  position: absolute;
  top: 50%;
  left: 19px;
  margin-top: -11px;
  content: '';
  z-index: 2;
}
.car_sidebar .add_bookmark i.not-added {
  display: block;
  position: relative;
  z-index: 1;
}
.car_sidebar .add_bookmark i.is-added {
  display: none;
  position: relative;
}
.car_sidebar .add_bookmark .fav-overlay {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background: #D03000;
  transform: scale(0);
  transition: all .4s;
  z-index: 1;
}
.car_sidebar .add_bookmark.added .fav-overlay {
  transform: scale(1);
}
.car_sidebar .add_bookmark.added i.not-added {
  display: none;
}
.car_sidebar .add_bookmark.added i.is-added {
  display: block;
}
.car_sidebar .add-to-favorites {
  position: relative;
  padding-left: 74px;
}
.car_sidebar .add-to-favorites:hover .fav-text {
  color: #ff3b00;
  transition: all .2s linear;
}
.car_sidebar .add-to-favorites .fav-text {
  font-size: 18px;
  color: #919193;
  line-height: 60px;
  transition: all .2s linear;
}
/*----------------------------------------------------------------------------------
4. Media queries
-----------------------------------------------------------------------------------*/
@media screen and (max-width: 1930px) {
  .tour_page_head .bottom-info {
    margin-top: 75px;
  }
}
@media screen and (max-width: 1520px) {
  .tour_page_body .left_content {
    max-width: 946px;
  }
  .car_sidebar.fixed {
    margin-right: -650px;
  }
  .tour_page_head .bottom-info .bottom-info-left {
    max-width: 800px;
  }
  .tour_page_body .left_content .stories .story_item {
    width: 224px;
    margin-right: 16px;
  }
  .tour_page_head .slider .slide {
    width: 420px;
    margin-right: 20px;
    height: 272px;
  }
  .tour_page.left-sidebar .car_sidebar.fixed {
    margin-left: -650px;
  }
}
@media screen and (max-width: 1400px) {
  .car_sidebar.fixed {
    margin-right: -640px;
  }
  .tour_page_body .right_content {
    max-width: 300px;
  }
  .tour_page_head .slider .slide {
    width: 413px;
  }
  .tour_page.left-sidebar .car_sidebar.fixed {
    margin-left: -640px;
  }
}
@media screen and (max-width: 1300px) {
  .tour_page_body .left_content {
    max-width: 844px;
  }
  .car_sidebar.fixed {
    margin-right: -590px;
  }
  .tour_page_body .left_content .programm .day_item-body .images .img {
    height: 248px;
  }
  .tour_page_body .left_content .stories .story_item {
    width: 270px;
  }
  .tour_page_body .left_content .stories .story_item:nth-child(3) {
    margin-right: 0;
  }
  .tour_page_body .left_content .stories .story_item:last-child {
    display: none;
  }
  .tour_page.left-sidebar .car_sidebar.fixed {
    margin-left: -590px;
  }
}
@media screen and (max-width: 1200px) {
  .tour_page_head .bottom-info .bottom-info-left .search-tour .search-form .date-col:nth-child(2) {
    clear: none;
  }
  .tour_page_head .bottom-info .bottom-info-left {
    max-width: 745px;
  }
  .tour_page_head .bottom-info .bottom-info-right {
    max-width: 188px;
  }
  .tour_page_head .bottom-info .bottom-info-right .info .cost {
    margin-left: 0;
    clear: both;
  }
  .tour_page_body .right_content {
    display: none;
  }
  .tour_page_body .left_content {
    max-width: 100%;
  }
  .tour_page_body .left_content .stories .story_item:last-child {
    display: block;
  }
  .tour_page_body .left_content .stories .story_item:nth-child(3) {
    margin-right: 16px;
  }
  .tour_page_body .left_content .stories .story_item {
    width: 223px;
    height: 360px;
  }
}
@media screen and (max-width: 1040px) {
  .mobile .tour_page_head .slider_wrap {
    overflow: auto;
    margin-left: -5%;
    width: 110%;
    padding-left: 5vw;
    padding-bottom: 12px;
  }
  .mobile .tour_page_head .slider_wrap .slider {
    width: auto;
    white-space: nowrap;
  }
  .mobile .tour_page_head .slider_wrap .slider .slide {
    white-space: normal;
    display: inline-block;
    float: none;
  }
  .mobile .tour_page_head .slider_wrap .slider .slide:last-child {
    margin-right: 5vw;
  }
  .write_comment .bottom .files .file-name span:before {
    opacity: 1;
  }
  .tour_page_body .left_content .stories .title_wrap .link {
    color: #ff3b00;
  }
}
@media screen and (max-width: 1000px) {
  .tour_page_head .top-info .tour_title {
    font-size: 40px;
  }
  .tour_page_head .slider .slide {
    width: 320px;
    height: 206px;
  }
  .tour_page_head .bottom-info .bottom-info-left .search-tour .search-form .num-col:nth-child(4) {
    clear: none;
  }
  .tour_page_head .bottom-info .bottom-info-left .search-tour .search-form .num-col .buttons button {
    background: #464646;
  }
  .tour_page_head .bottom-info .bottom-info-right {
    width: 100%;
    max-width: 100%;
    margin-top: 30px;
  }
  .tour_page_head .bottom-info .bottom-info-right .info .cost {
    margin-left: 10px;
    clear: none;
  }
  .tour_page_head .bottom-info {
    margin-top: 45px;
  }
  .tour_page_body .left_content .programm .day_item-head {
    padding-left: 200px;
  }
  .tour_page_body .left_content .programm .day_item-head .preview .p {
    font-size: 21px;
  }
  .tour_page_body .left_content .programm .day_item-body .images .img {
    height: 30vw;
  }
  .tour_page_body .left_content .stories .story_item {
    width: 260px;
  }
  .tour_page_body .left_content .stories {
    overflow: visible;
  }
  .tour_page_body .left_content .stories .stries_slider {
    margin-left: -5%;
    width: 110%;
    overflow: auto;
    padding-left: 5vw;
    padding-bottom: 14px;
  }
  .tour_page_body .left_content .stories .stries_slider .scroll {
    white-space: nowrap;
    width: auto;
  }
  .tour_page_body .left_content .stories .stries_slider .scroll .story_item {
    display: inline-block;
    float: none;
    white-space: normal;
  }
  .tour_page_body .left_content .stories .stries_slider .scroll .story_item:last-child {
    margin-right: 5vw;
  }
  .tour_page_head .top-info .controls .arrows {
    display: none;
  }
  .tour_page_head .top-info .controls .add_bookmark {
    margin-right: 0;
  }
  .tour_page_head .top-info .controls {
    bottom: auto;
    top: 0;
  }
  .tour_page_head .top-info {
    padding-right: 90px;
  }
  .write_comment .title {
    width: 100%;
    text-align: center;
  }
  .write_comment .rating {
    width: 100%;
    text-align: center;
  }
  .write_comment .rating-text {
    width: 100%;
    margin-right: 0;
    text-align: center;
  }
  .write_comment .rating-stars {
    display: inline-block;
    float: none;
  }
  .tour_page_body .left_content .title {
    margin-bottom: 15px;
  }
  .write_comment .rating-text {
    margin-bottom: 5px;
  }
  .write_comment .bottom .files {
    display: block;
  }
  .write_comment .bottom .files .file-name {
    font-size: 18px;
    margin-top: 16px;
  }
  .write_comment .bottom {
    margin-right: 20px;
  }
  .write_comment {
    padding: 30px;
  }
  .tour_page_body .left_content .pagination {
    text-align: center;
  }
  .comments-block .comments .comment_item .reply_form .top .cancel {
    font-size: 17px;
  }
  .comments-block .comments .comment_item .reply_form .top .cancel {
    width: 42px;
    height: 42px;
    /* background: url(../img/close.svg) center center no-repeat; */
    background-size: 14px 14px;
    line-height: normal;
    position: absolute;
    right: 0;
    top: 0;
  }
  .comments-block .comments .comment_item .reply_form .top .cancel span {
    display: none;
  }
  .comments-block .comments .comment_item .reply_form .top {
    position: relative;
    padding-right: 60px;
  }
}
@media screen and (max-width: 760px) {
  .tour_page_head .top-info .country {
    font-size: 22px;
  }
  .tour_page_head .top-info .country:before {
    top: 9px;
  }
  .tour_page_head .top-info .tour_title {
    font-size: 33px;
  }
  .tour_page_body .left_content .title {
    text-align: center;
  }
  .tour_page_body .left_content .included ul li {
    max-width: 100%;
    width: 100%;
    padding-top: 18px;
    padding-bottom: 18px;
    padding-right: 0;
  }
  .tour_page_body .left_content .included ul li:nth-child(1),
  .tour_page_body .left_content .included ul li:nth-child(2) {
    border-top: 1px solid #dfdfdf;
    padding-top: 18px;
  }
  .tour_page_body .left_content .included ul li:nth-child(1) {
    border-top: 0;
  }
  .tour_page_body .left_content .included ul li:nth-child(1):before,
  .tour_page_body .left_content .included ul li:nth-child(2):before,
  .tour_page_body .left_content .included ul li:before {
    top: 24px;
  }
  .tour_page_body .left_content .included ul li:nth-child(1):after,
  .tour_page_body .left_content .included ul li:nth-child(2):after,
  .tour_page_body .left_content .included ul li:after {
    top: 40px;
  }
  .tour_page_body .left_content .title {
    text-align: left;
  }
  .tour_page_body .left_content .write_comment .title {
    text-align: center;
  }
  .comments-block .title_wrap {
    margin-bottom: 18px;
  }
}
@media screen and (max-width: 640px) {
  .tour_page_head .bottom-info .bottom-info-right .info {
    text-align: center;
  }
  .tour_page_head .bottom-info .bottom-info-right .info .days,
  .tour_page_head .bottom-info .bottom-info-right .info .cost {
    display: inline-block;
    float: none;
  }
  .tour_page_head .bottom-info .bottom-info-right {
    text-align: center;
  }
  .tour_page_head .bottom-info .bottom-info-right .btn {
    display: inline-block;
    float: none;
    width: 100%;
    max-width: 300px;
  }
  .tour_page_head .bottom-info .bottom-info-right .rating .rating-stars {
    display: inline-block;
    float: none;
    vertical-align: top;
  }
  .tour_page_head .bottom-info .bottom-info-right .rating .rating-text {
    display: inline-block;
    float: none;
    vertical-align: top;
  }
  .write_comment .input {
    width: 100%;
    margin-left: 0;
    margin-bottom: 14px;
  }
  .write_comment .textarea {
    margin-top: 0;
  }
  .write_comment .bottom {
    margin-top: 14px;
    margin-right: 0;
    display: block;
    text-align: center;
  }
  .write_comment .bottom .files {
    width: 100%;
    max-width: 100%;
  }
  .write_comment .bottom .files .label {
    margin-right: 0;
    max-width: 300px;
    width: 100%;
    display: inline-block;
    float: none;
  }
  .write_comment .bottom .files .file-name {
    text-align: center;
    display: block;
  }
  .write_comment .bottom .submit {
    margin-left: 0;
    display: inline-block;
    float: none;
    max-width: 300px;
    width: 100%;
    margin-top: 20px;
  }
  .comments-block .comments .comment_item_bottom .rating {
    width: 100%;
    max-width: 100%;
  }
  .comments-block .comments .comment_item_bottom .reply {
    float: right;
  }
  .comments-block .comments .comment_item_bottom {
    margin-top: 4px;
  }
  .comments-block .comments .comment_item.answer_comment .comment_item_bottom .reply {
    float: left;
  }
  .tour_page_head .top-info .country {
    font-size: 18px;
  }
  .tour_page_head .top-info .country:before {
    top: 7px;
  }
  .tour_page_head .top-info .controls {
    top: -9px;
  }
  .tour_page_head .top-info .country {
    margin-bottom: 20px;
  }
}
@media screen and (max-width: 480px) {
  .tour_page_head .top-info .tour_title {
    font-size: 31px;
  }
  .tour_page_head .top-info {
    padding-right: 0;
  }
  .tour_page_head .top-info .country {
    padding-right: 60px;
  }
  .tour_page_head .top-info .controls {
    top: -6px;
  }
  .comments-block .title_wrap .counter {
    font-size: 32px;
    padding-top: 2px;
  }
  .tour_page_head .bottom-info {
    margin-top: 26px;
  }
  .tour_page_head .bottom-info .bottom-info-left .search-tour .search-form .num-col {
    border-bottom: 1px solid rgba(255, 255, 255, 0.24);
    padding-bottom: 30px;
  }
  .search-form .num-col.last {
    width: 50%;
  }
  .tour_page_body .left_content .title {
    font-size: 31px;
  }
  .tour_page_body .left_content .programm .day_item-head .preview .image {
    width: 44px;
    height: 44px;
    margin-right: 0;
  }
  .tour_page_body .left_content .programm .day_item-head .preview {
    width: 44px;
    height: 44px;
    top: 14px;
    margin-top: 0;
  }
  .tour_page_body .left_content .programm .day_item-head .preview .p {
    white-space: nowrap;
    position: absolute;
    left: 100%;
    top: -5px;
    font-size: 21px;
    line-height: normal;
    margin-left: 14px;
  }
  .tour_page_body .left_content .programm .day_item-head {
    padding-left: 58px;
  }
  .tour_page_body .left_content .programm .day_item-head ._title {
    font-size: 18px;
    height: auto;
    display: block;
    float: left;
    padding-top: 28px;
  }
  .tour_page_body .left_content .programm .day_item-body .images .item {
    width: 300px;
    margin-right: 20px;
  }
  .tour_page_body .left_content .programm .day_item-body .images .img {
    height: 290px;
  }
  .tour_page_body .left_content .programm .day_item-body .images {
    margin-left: -5%;
    width: 110%;
    padding-left: 5vw;
    overflow: auto;
    padding-bottom: 12px;
  }
  .tour_page_body .left_content .programm .day_item-body .images .scroll {
    width: auto;
    white-space: nowrap;
  }
  .tour_page_body .left_content .programm .day_item-body .images .scroll .item {
    white-space: normal;
    display: inline-block;
    float: none;
  }
  .tour_page_body .left_content .programm .day_item-body .images .scroll .item:last-child {
    margin-right: 5vw;
  }
  .write_comment .bottom .files .label,
  .write_comment .bottom .submit {
    max-width: 100%;
  }
  .comments-block .comments .comment_item.answer_comment .comment_item_bottom {
    position: relative;
    padding-left: 76px;
    margin-top: 11px;
  }
  .comments-block .comments .comment_item.answer_comment .comment_item_bottom .reply {
    position: absolute;
    top: 0;
    left: 0;
  }
  .comments-block .comments .comment_item.answer_comment .comment_item_bottom .author {
    width: 100%;
    padding-right: 47px;
    position: relative;
    text-align: right;
  }
  .comments-block .comments .comment_item.answer_comment .comment_item_bottom .author .userpic {
    position: absolute;
    right: 0;
    top: 0;
  }
  .comments-block .comments .comment_item_top {
    padding: 20px;
  }
  .write_comment {
    padding: 26px 16px;
  }
  .write_comment .rating-text {
    font-size: 19px;
  }
  .write_comment .input {
    font-size: 17px;
    padding: 0 13px;
  }
  .write_comment .textarea {
    padding: 13px;
    font-size: 17px;
    height: 200px;
  }
  .write_comment .bottom .files .label {
    font-size: 17px;
  }
  .write_comment .bottom .submit {
    font-size: 17px;
  }
  .write_comment .bottom .files .file-name {
    font-size: 16px;
  }
  .tour_page_body .left_content .location .map {
    height: 360px;
  }
  .tour_page_body .left_content .write_comment .title {
    font-size: 24px;
    margin-bottom: 8px;
  }
}
@media screen and (max-width: 400px) {
  .tour_page_body .left_content .programm .day_item-body .images .item {
    width: 278px;
    margin-right: 10px;
  }
  .tour_page_body .left_content .stories .stries_slider .scroll .story_item {
    margin-right: 10px;
  }
  .tour_page_head .slider .slide {
    width: 270px;
    margin-right: 10px;
  }
  .comments-block .title_wrap .val {
    width: 50px;
    height: 50px;
    line-height: 50px;
    font-size: 20px;
  }
  .comments-block .title_wrap .title {
    line-height: 50px;
    margin-right: 13px;
  }
  .comments-block .title_wrap .counter {
    line-height: 50px;
  }
  .comments-block .title_wrap .val {
    margin-right: 11px;
  }
}
/*Import "Stories list page" styles*/
/*------------------------------------------------------------------
[Table of contents]

1. Page styles
2. Media queries
-------------------------------------------------------------------*/
/*----------------------------------------------------------------------------------
1. Page styles
-----------------------------------------------------------------------------------*/
.stories-list-page .page_head {
  position: relative;
  padding-right: 350px;
  margin-bottom: 42px;
}
.stories-list-page .page_head .select_wrap {
  width: 310px;
  height: 58px;
  background: #F7F7F7;
  position: absolute;
  right: 0;
  bottom: 0;
  border-radius: 7px;
  overflow: hidden;
}
.stories-list-page .page_head .select_wrap:before {
  width: 16px;
  height: 22px;
  /* background: url(../img/geo-red.svg) center center no-repeat; */
  background-size: contain;
  position: absolute;
  content: '';
  top: 50%;
  margin-top: -11px;
  right: 24px;
}
.stories-list-page .page_head .select_wrap select {
  width: 100%;
  height: 100%;
  border: 0;
  background: none;
  font-size: 21px;
  color: rgba(145, 145, 147, 0.47);
  padding: 0 50px 0 28px;
  font-weight: 400;
  position: relative;
  z-index: 1;
}
.stories-list-page .page_head .select_wrap select.selected {
  color: #222;
}
.stories-list-page .page_head .select_wrap select:focus {
  outline: 0;
}
.stories-list-page .page_body {
  padding-bottom: 30px;
}
.stories-list-page .page_body .stories_list .story_item {
  margin-bottom: 35px;
}
.stories-list-page .page_body .btn_wrap {
  text-align: center;
  margin-top: 25px;
}
/* .stories-list-page.right-sidebar .page_body .stories_list .story_item,
.stories-list-page.left-sidebar .page_body .stories_list .story_item {
  width: 250px;
  margin-right: 30px;
}
.stories-list-page.right-sidebar .page_body .stories_list .story_item:nth-child(5n),
.stories-list-page.left-sidebar .page_body .stories_list .story_item:nth-child(5n) {
  margin-right: 30px;
}
.stories-list-page.right-sidebar .page_body .stories_list .story_item:nth-child(4n),
.stories-list-page.left-sidebar .page_body .stories_list .story_item:nth-child(4n) {
  margin-right: 0;
} */
.stories-list-page.right-sidebar .pagination,
.stories-list-page.left-sidebar .pagination {
  text-align: left;
}
.stories-list-page.right-sidebar .right_content,
.stories-list-page.left-sidebar .right_content {
  float: right;
}
.stories-list-page.right-sidebar .right_content ._block,
.stories-list-page.left-sidebar .right_content ._block {
  margin-top: 0;
}
.stories-list-page.left-sidebar .right_content {
  float: left;
}
.stories-list-page.left-sidebar .left_content {
  float: right;
}
/*----------------------------------------------------------------------------------
2. Media queries
-----------------------------------------------------------------------------------*/
/* @media screen and (max-width: 1520px) {
  .stories-list-page .page_body .stories_list .story_item {
    width: 240px;
    margin-right: 25px;
    margin-bottom: 25px;
  }
  .stories-list-page.right-sidebar .page_body .stories_list .story_item,
  .stories-list-page.left-sidebar .page_body .stories_list .story_item {
    width: 222px;
    margin-right: 20px;
    height: 370px;
  }
  .stories-list-page.right-sidebar .page_body .stories_list .story_item:nth-child(5n) {
    margin-right: 20px;
  }
  .stories-list-page.left-sidebar .page_body .stories_list .story_item:nth-child(5n) {
    margin-right: 20px;
  }
} */
/* @media screen and (max-width: 1400px) {
  .stories-list-page .page_body .stories_list .story_item {
    width: 236px;
  }
}
@media screen and (max-width: 1300px) {
  .stories-list-page .page_body .stories_list .story_item {
    width: 270px;
    margin-right: 33px;
  }
  .stories-list-page .page_body .stories_list .story_item:nth-child(5n) {
    margin-right: 33px;
  }
  .stories-list-page .page_body .stories_list .story_item:nth-child(4n) {
    margin-right: 0;
  }
  .stories-list-page.right-sidebar .page_body .stories_list .story_item,
  .stories-list-page.left-sidebar .page_body .stories_list .story_item {
    width: 266px;
    margin-bottom: 20px;
    height: 400px;
  }
  .stories-list-page.right-sidebar .page_body .stories_list .story_item:nth-child(4n),
  .stories-list-page.left-sidebar .page_body .stories_list .story_item:nth-child(4n) {
    margin-right: 20px;
  }
  .stories-list-page.right-sidebar .page_body .stories_list .story_item:nth-child(3n),
  .stories-list-page.left-sidebar .page_body .stories_list .story_item:nth-child(3n) {
    margin-right: 0;
  }
}
@media screen and (max-width: 1200px) {
  .stories-list-page .page_body .stories_list .story_item {
    width: 222px;
    height: 364px;
    margin-right: 17px;
    margin-bottom: 17px;
  }
  .stories-list-page .page_body .stories_list .story_item:nth-child(5n) {
    margin-right: 17px;
  }
  .stories-list-page.right-sidebar .page_body .stories_list .story_item,
  .stories-list-page.left-sidebar .page_body .stories_list .story_item {
    width: 284px;
  }
  .stories-list-page.right-sidebar .page_body .stories_list .story_item:nth-child(3n),
  .stories-list-page.left-sidebar .page_body .stories_list .story_item:nth-child(3n) {
    margin-right: 20px;
  }
  .stories-list-page.right-sidebar .page_body .stories_list .story_item:nth-child(even),
  .stories-list-page.left-sidebar .page_body .stories_list .story_item:nth-child(even) {
    margin-right: 0;
  }
} */
/* @media screen and (max-width: 1000px) {
  .stories-list-page .page_body .stories_list .story_item,
  .stories-list-page.right-sidebar .page_body .stories_list .story_item,
  .stories-list-page.left-sidebar .page_body .stories_list .story_item {
    width: 31.3%;
    margin-right: 3%;
    margin-bottom: 3%;
    height: 44vw;
  }
  .stories-list-page .page_body .stories_list .story_item:nth-child(even),
  .stories-list-page.right-sidebar .page_body .stories_list .story_item:nth-child(even),
  .stories-list-page.left-sidebar .page_body .stories_list .story_item:nth-child(even) {
    margin-right: 3%;
  }
  .stories-list-page .page_body .stories_list .story_item:nth-child(4n),
  .stories-list-page.right-sidebar .page_body .stories_list .story_item:nth-child(4n),
  .stories-list-page.left-sidebar .page_body .stories_list .story_item:nth-child(4n) {
    margin-right: 3%;
  }
  .stories-list-page .page_body .stories_list .story_item:nth-child(5n),
  .stories-list-page.right-sidebar .page_body .stories_list .story_item:nth-child(5n),
  .stories-list-page.left-sidebar .page_body .stories_list .story_item:nth-child(5n) {
    margin-right: 3%;
  }
  .stories-list-page .page_body .stories_list .story_item:nth-child(3n),
  .stories-list-page.right-sidebar .page_body .stories_list .story_item:nth-child(3n),
  .stories-list-page.left-sidebar .page_body .stories_list .story_item:nth-child(3n) {
    margin-right: 0;
  }
  .stories-list-page .page_head .select_wrap {
    clear: both;
    float: left;
    position: relative;
    left: auto;
    right: auto;
    top: auto;
    margin-top: 30px;
  }
  .stories-list-page .page_head {
    padding-right: 0;
  }
  .stories-list-page.right-sidebar .right_content,
  .stories-list-page.left-sidebar .right_content {
    clear: both;
    width: 100%;
    max-width: 100%;
    margin-top: 50px;
  }
  .stories-list-page.right-sidebar .left_content,
  .stories-list-page.left-sidebar .left_content {
    width: 100%;
    max-width: 100%;
  }
} */
@media screen and (max-width: 760px) {
  .stories-list-page .page_body .stories_list .story_item,
  .stories-list-page.right-sidebar .page_body .stories_list .story_item,
  .stories-list-page.left-sidebar .page_body .stories_list .story_item {
    width: 49%;
    /* margin-right: 2%;
    margin-bottom: 2%; */
    height: 68vw;
  }
  /* .stories-list-page .page_body .stories_list .story_item:nth-child(4n),
  .stories-list-page.right-sidebar .page_body .stories_list .story_item:nth-child(4n),
  .stories-list-page.left-sidebar .page_body .stories_list .story_item:nth-child(4n) {
    margin-right: 2%;
  }
  .stories-list-page .page_body .stories_list .story_item:nth-child(5n),
  .stories-list-page.right-sidebar .page_body .stories_list .story_item:nth-child(5n),
  .stories-list-page.left-sidebar .page_body .stories_list .story_item:nth-child(5n) {
    margin-right: 2%;
  }
  .stories-list-page .page_body .stories_list .story_item:nth-child(3n),
  .stories-list-page.right-sidebar .page_body .stories_list .story_item:nth-child(3n),
  .stories-list-page.left-sidebar .page_body .stories_list .story_item:nth-child(3n) {
    margin-right: 2%;
  } */
  /* .stories-list-page .page_body .stories_list .story_item:nth-child(even),
  .stories-list-page.right-sidebar .page_body .stories_list .story_item:nth-child(even),
  .stories-list-page.left-sidebar .page_body .stories_list .story_item:nth-child(even) {
    margin-right: 0;
  } */
  .stories-list-page.right-sidebar .pagination,
  .stories-list-page.left-sidebar .pagination {
    text-align: center;
  }
}
@media screen and (max-width: 480px) {
  .stories-list-page .page_head .select_wrap {
    width: 100%;
  }
  .stories-list-page .page_body .stories_list .story_item ._content .text {
    font-size: 17px;
  }
  .stories-list-page .page_body .stories_list .story_item ._content .flag {
    width: 56px;
    height: 56px;
  }
  .stories-list-page .page_body .stories_list .story_item ._content .country {
    font-size: 25px;
  }
  .stories-list-page .page_head .select_wrap {
    margin-top: 20px;
  }
  .stories-list-page .page_head {
    margin-bottom: 36px;
  }
}
@media screen and (max-width: 440px) {
  .stories-list-page .page_body .stories_list .story_item,
  .stories-list-page.right-sidebar .page_body .stories_list .story_item,
  .stories-list-page.left-sidebar .page_body .stories_list .story_item {
    width: 100%;
    margin-right: 0;
    height: 100vw;
  }
  .stories-list-page .page_body .stories_list .story_item ._content .country {
    font-size: 33px;
  }
  .stories-list-page .page_head .select_wrap select {
    padding-left: 18px;
    font-size: 17px;
  }
  .stories-list-page .page_body .stories_list .story_item,
  .stories-list-page.right-sidebar .page_body .stories_list .story_item,
  .stories-list-page.left-sidebar .page_body .stories_list .story_item {
    margin-bottom: 20px;
  }
}
/*Import "Blog list page" styles*/
/*------------------------------------------------------------------
[Table of contents]

1. Page styles
1.1. Left Sifebar
1.2. Search Results

2. Media queries
-------------------------------------------------------------------*/
/*----------------------------------------------------------------------------------
1. Page styles
-----------------------------------------------------------------------------------*/
.blog-list-page .page_head {
  position: relative;
  padding-right: 680px;
  margin-bottom: 42px;
}
.blog-list-page .page_head .select_wrap {
  width: 310px;
  height: 58px;
  background: #F7F7F7;
  position: relative;
  border-radius: 7px;
  overflow: hidden;
  margin-right: 25px;
}
.blog-list-page .page_head .select_wrap:before {
  width: 9px;
  height: 9px;
  background: #D03000;
  position: absolute;
  content: '';
  top: 50%;
  margin-top: -4px;
  right: 24px;
  border-radius: 50%;
}
.blog-list-page .page_head .select_wrap select {
  width: 100%;
  height: 100%;
  border: 0;
  background: none;
  font-size: 21px;
  color: rgba(145, 145, 147, 0.47);
  padding: 0 50px 0 28px;
  font-weight: 400;
  position: relative;
  z-index: 1;
}
.blog-list-page .page_head .select_wrap select.selected {
  color: #222;
}
.blog-list-page .page_head .select_wrap select:focus {
  outline: 0;
}
.blog-list-page .page_head .filters {
  width: auto;
  float: right;
  position: absolute;
  right: 0;
  bottom: 0;
}
.blog-list-page .page_body .pagination {
  text-align: left;
}
.blog-list-page .page_body .blog-list .blog_item {
  margin-bottom: 4%;
}
.blog-list-page .page_body .blog-list .blog_item:last-child {
  display: block;
}
.blog-list-page .page_body .right_content {
  float: right;
}
.blog-list-page .page_body .right_content .category_block {
  background: #F7F7F7;
  padding: 40px 30px;
  border-radius: 7px;
}
.blog-list-page .page_body .right_content .category_block ul li {
  margin-bottom: 15px;
}
.blog-list-page .page_body .right_content .category_block ul li:last-child {
  margin-bottom: 0;
}
.blog-list-page .page_body .right_content .category_block ul li a {
  width: 100%;
  display: block;
  float: left;
  font-size: 18px;
  color: #222;
  position: relative;
}
.blog-list-page .page_body .right_content .category_block ul li a .count {
  position: absolute;
  right: 0;
  color: #919193;
  top: 0;
}
.blog-list-page .page_body .right_content .category_block ul li a.active {
  color: #FF3B00;
}
.blog-list-page .page_body .right_content .category_block ul li a.active .count {
  color: #003BFF;
}
.blog-list-page .page_body .right_content .gallery_block {
  background: #222;
  border-radius: 7px;
  padding: 40px 30px;
}
.blog-list-page .page_body .right_content .gallery_block .block_title {
  color: #fff;
}
.blog-list-page .page_body .right_content .gallery_block .images .img {
  width: 114px;
  height: 110px;
  border-radius: 10px;
  overflow: hidden;
  margin-right: 20px;
  margin-bottom: 20px;
  display: block;
  float: left;
}
.blog-list-page .page_body .right_content .gallery_block .images .img:nth-child(even) {
  margin-right: 0;
}
.blog-list-page .page_body .right_content .gallery_block .images .img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
}
.blog-list-page .page_body .right_content .gallery_block .link {
  font-size: 18px;
  color: #fff;
}
.blog-list-page .page_body .right_content .archive_block {
  background: #0030D0;
  padding: 40px 30px;
  border-radius: 7px;
}
.blog-list-page .page_body .right_content .archive_block .block_title {
  color: #fff;
}
.blog-list-page .page_body .right_content .archive_block ul li {
  margin-bottom: 15px;
}
.blog-list-page .page_body .right_content .archive_block ul li:last-child {
  margin-bottom: 0;
}
.blog-list-page .page_body .right_content .archive_block ul li a {
  width: 100%;
  display: block;
  float: left;
  font-size: 18px;
  color: #fff;
  position: relative;
}
.blog-list-page .page_body .right_content .archive_block ul li a .count {
  position: absolute;
  right: 0;
  color: #fff;
  top: 0;
}
.blog-list-page .search_wrap {
  width: 310px;
  padding-right: 52px;
  position: relative;
  background: #F7F7F7;
  border-radius: 7px;
}
.blog-list-page .search_wrap.mobile_search {
  display: none;
}
.blog-list-page .search_wrap .submit {
  height: 58px;
  position: absolute;
  top: 0;
  width: 40px;
  right: 10px;
  background: none;
}
.blog-list-page .search_wrap .submit:before {
  width: 22px;
  height: 22px;
  /* background: url(../img/search.svg) center center no-repeat; */
  background-size: contain;
  position: absolute;
  content: '';
  top: 50%;
  margin-top: -11px;
  right: 50%;
  margin-right: -11px;
}
.blog-list-page .search_wrap .input {
  width: 100%;
  display: block;
  float: left;
  height: 58px;
  background: none;
  border: 0;
  padding-left: 28px;
  font-weight: 400;
  font-size: 21px;
}
.blog-list-page .search_wrap .input::-webkit-input-placeholder {
  color: rgba(145, 145, 147, 0.47);
}
.blog-list-page .search_wrap .input::-moz-placeholder {
  color: rgba(145, 145, 147, 0.47);
}
.blog-list-page .search_wrap .input:-moz-placeholder {
  color: rgba(145, 145, 147, 0.47);
}
.blog-list-page .search_wrap .input:-ms-input-placeholder {
  color: rgba(145, 145, 147, 0.47);
}
.blog-list-page.right-sidebar .blog_item,
.blog-list-page.left-sidebar .blog_item {
  width: 48%;
  margin-right: 4%;
}
.blog-list-page.right-sidebar .blog_item:nth-child(even),
.blog-list-page.left-sidebar .blog_item:nth-child(even) {
  margin-right: 0;
}
.blog-list-page.left-sidebar .left_content {
  float: right;
}
.blog-list-page.left-sidebar .page_body .right_content {
  float: left;
}
.blog-list-page.search-results .pagination {
  text-align: center;
}
/*----------------------------------------------------------------------------------
2. Media queries
-----------------------------------------------------------------------------------*/
@media screen and (max-width: 1200px) {
  .blog-list-page .page_head {
    padding-right: 0;
  }
  .blog-list-page .page_head .filters {
    position: relative;
    right: auto;
    bottom: auto;
    float: left;
    margin-top: 22px;
  }
  .blog-list-page .page_body .blog-list .blog_item {
    margin-bottom: 4%;
  }
  .blog-list-page .page_body .blog-list .blog_item:nth-child(even) {
    margin-right: 0;
  }
  .blog-list-page .page_body .blog-list .blog_item:nth-child(odd) {
    clear: both;
  }
  .blog-list-page .page_body .blog-list .blog_item {
    width: 100%;
    margin-bottom: 50px;
    margin-right: 0;
  }
  .blog-list-page .page_body .blog-list .blog_item:last-child {
    margin-bottom: 0;
  }
}
@media screen and (max-width: 1000px) {
  .blog-list-page .page_body .right_content .gallery_block .images .img {
    width: 48%;
    margin-right: 4%;
    margin-bottom: 4%;
    height: 28vw;
  }
}
@media screen and (max-width: 760px) {
  .blog-list-page .page_head .select_wrap {
    width: 100%;
    margin-right: 0;
  }
  .blog-list-page .search_wrap {
    width: 100%;
    margin-top: 16px;
  }
  .blog-list-page .page_head .filters {
    width: 100%;
  }
  .blog-list-page .page_body .right_content .gallery_block .images .img {
    height: 37vw;
  }
  .blog-list-page .search_wrap.mobile_search {
    display: block;
  }
  .blog-list-page .page_body .right_content .search_wrap {
    display: none;
  }
  .blog-list-page .page_body .pagination {
    text-align: center;
  }
}
@media screen and (max-width: 640px) {
  margin-bottom: 40px;
}
@media screen and (max-width: 480px) {
  .blog-list-page .page_head .select_wrap select {
    font-size: 18px;
    padding-left: 18px;
  }
  .blog-list-page .search_wrap .input {
    font-size: 18px;
    padding-left: 18px;
  }
}
/*Import "Blog page" styles*/
/*------------------------------------------------------------------
[Table of contents]

1. Page styles
1.1. Left Sifebar

2. Media queries
-------------------------------------------------------------------*/
/*----------------------------------------------------------------------------------
1. Page styles
-----------------------------------------------------------------------------------*/
.blog-single-page .blog_single-head {
  margin-bottom: 10px;
}
.blog-single-page .blog_single-head_top {
  height: 490px;
  border-radius: 8px;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding: 45px 50px;
}
.blog-single-page .blog_single-head_top:before {
  background: -moz-linear-gradient(top, rgba(0, 0, 0, 0) 0%, #000000 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 0%, #000000 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, #000000 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00000000', endColorstr='#000000', GradientType=0);
  /* IE6-9 */
  opacity: .63;
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 13px;
}
.blog-single-page .blog_single-head_top .car_tags {
  position: relative;
  z-index: 1;
}
.blog-single-page .blog_single-head_top .car_tags .car_tag {
  height: 28px;
  line-height: 28px;
  background: #000;
  width: auto;
  padding: 0 10px;
  border-radius: 5px;
  font-size: 15px;
  color: #fff;
  margin-right: 8px;
}
.blog-single-page .blog_single-head_top .car_tags .car_tag.red {
  background: #D03000;
}
.blog-single-page .blog_single-head_top .car_tags .car_tag.green {
  background: #2FB26B;
}
.blog-single-page .blog_single-head_top .car_tags .car_tag.blue {
  background: #1140DE;
}
.blog-single-page .blog_single-head_top .car_tags .car_tag.black {
  background: #222;
}
.blog-single-page .blog_single-head_top .title {
  position: relative;
  z-index: 1;
  font-size: 48px;
  color: #fff;
  margin-bottom: 0;
}
.blog-single-page .blog_single-head_bottom {
  margin-top: 18px;
}
.blog-single-page .blog_single-head_bottom .author {
  position: relative;
  padding-left: 52px;
}
.blog-single-page .blog_single-head_bottom .author .userpic {
  width: 42px;
  height: 42px;
  border-radius: 50%;
  overflow: hidden;
  position: absolute;
  left: 0;
  top: 0;
}
.blog-single-page .blog_single-head_bottom .author .name {
  font-size: 15px;
  color: #919193;
  height: 42px;
  display: flex;
  align-items: center;
}
.blog-single-page .blog_single-body .description {
  font-size: 26px;
  font-style: italic;
  color: #222;
  margin: 15px 0;
}
.blog-single-page .blog_single-body p:not([class]) {
  color: #919193;
  font-size: 21px;
  margin: 15px 0;
}
.blog-single-page .blog_single-body h2 {
  font-family: 'Prata', serif;
  font-size: 30px;
  margin: 34px 0 13px;
}
.blog-single-page .blog_single-body .stories {
  overflow: visible;
  padding: 0;
  margin-bottom: 20px;
}
.blog-single-page .blog_single-body .stories h2 {
  margin-bottom: 28px;
}
.blog-single-page .blog_single-body .stories .story_item {
  width: 245px;
}
.blog-single-page .blog_single-body .stories .story_item:nth-child(4n) {
  margin-right: 0;
}
.blog-single-page .blog_single-body .two-colums .col {
  width: 48%;
  margin-right: 4%;
}
.blog-single-page .blog_single-body .two-colums .col:last-child {
  margin-right: 0;
}
.blog-single-page .blog_single-body .quote {
  background: #003BFF;
  padding: 34px 45px;
  padding-left: 82px;
  border-radius: 24px;
  border-bottom-left-radius: 0;
  position: relative;
  margin: 22px 0;
}
.blog-single-page .blog_single-body .quote:before {
  width: 24px;
  height: 24px;
  /* background: url(../img/quote.svg) center center no-repeat; */
  background-size: contain;
  position: absolute;
  content: '';
  left: 38px;
  top: 43px;
}
.blog-single-page .blog_single-body .quote p {
  font-size: 26px;
  color: #fff;
  font-style: italic;
  margin: 0;
}
.blog-single-page .blog_single-body .gallery-block {
  margin-top: 15px;
}
.blog-single-page .blog_single-body .gallery-block .img {
  width: 32%;
  margin-right: 2%;
  margin-bottom: 2%;
  border-radius: 8px;
  overflow: hidden;
  display: block;
  float: left;
}
.blog-single-page .blog_single-body .gallery-block .img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
}
.blog-single-page .blog_single-body .gallery-block .img:nth-child(3n) {
  margin-right: 0;
}
.blog-single-page .blog_single-body .video_block {
  margin-bottom: 14px;
  margin-top: 15px;
}
.blog-single-page .blog_single-body .video_block span {
  font-size: 16px;
  color: #919193;
  display: inline-block;
  margin-top: 10px;
}
.blog-single-page .blog_single-body .video {
  padding-bottom: 56.25%;
  height: 0;
  background: #000;
  position: relative;
  border-radius: 8px;
  overflow: hidden;
}
.blog-single-page .blog_single-body .video iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}
.blog-single-page .blog_single-body .img-block {
  margin: 22px 0;
}
.blog-single-page .blog_single-body .img-block img {
  max-width: 100%;
  display: block;
  float: left;
  border-radius: 8px;
}
.blog-single-page .blog_single-body .img-block span {
  font-size: 16px;
  color: #919193;
  display: inline-block;
  margin-top: 10px;
}
.blog-single-page .blog_single-body .tour-block {
  margin-top: 15px;
  margin-bottom: 15px;
}
.blog-single-page .blog_single-body .tour-block .item {
  display: block;
  float: left;
  width: 100%;
}
.blog-single-page .blog_single-body .tour-block .item:hover .item_left .image .shadow {
  opacity: 1;
  transition: all .2s linear;
}
.blog-single-page .blog_single-body .tour-block .item:hover .item_right {
  background: #f5f5f5;
  transition: all .2s linear;
}
.blog-single-page .blog_single-body .tour-block .item_left {
  max-width: 394px;
}
.blog-single-page .blog_single-body .tour-block .item_left .image {
  height: 23em;
  background: #000;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 13px;
  position: relative;
}
.blog-single-page .blog_single-body .tour-block .item_left .shadow {
  filter: blur(10px);
  -webkit-filter: blur(10px);
  -moz-filter: blur(10px);
  position: absolute;
  top: 10px;
  left: -6px;
  right: -6px;
  bottom: -10px;
  z-index: -1;
  width: auto;
  opacity: 0;
  transition: all 0.21s ease-out;
  will-change: opacity;
  transition: all .2s linear;
}
.blog-single-page .blog_single-body .tour-block .item_right {
  background: rgba(245, 245, 245, 0.74);
  border-radius: 13px;
  min-height: 368px;
  max-width: 668px;
  float: right;
  padding: 34px 44px;
  position: relative;
  transition: all .2s linear;
}
.blog-single-page .blog_single-body .tour-block .item_right .country {
  font-size: 16px;
  color: #222;
  padding-left: 12px;
  position: relative;
  margin-bottom: 18px;
}
.blog-single-page .blog_single-body .tour-block .item_right .country:before {
  width: 6px;
  height: 6px;
  background: #D03000;
  border-radius: 50%;
  content: '';
  position: absolute;
  left: 0;
  top: 8px;
}
.blog-single-page .blog_single-body .tour-block .item_right .rating-stars {
  width: auto;
  margin-right: 12px;
}
.blog-single-page .blog_single-body .tour-block .item_right .rating-stars .star {
  position: relative;
  width: 24px;
  height: 28px;
}
.blog-single-page .blog_single-body .tour-block .item_right .rating-stars .star:before {
  width: 18px;
  height: 16px;
  /* background: url(../img/star.svg) center center no-repeat; */
  background-size: contain;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -8px;
  margin-left: -9px;
  content: '';
}
.blog-single-page .blog_single-body .tour-block .item_right .rating-stars .star.filled:before {
  display: none;
}
.blog-single-page .blog_single-body .tour-block .item_right .rating-stars .star.filled:after {
  width: 18px;
  height: 16px;
  /* background: url(../img/star.svg) center center no-repeat; */
  background-size: contain;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -8px;
  margin-left: -9px;
  content: '';
}
.blog-single-page .blog_single-body .tour-block .item_right .item_title {
  font-size: 33px;
  font-family: 'Prata', serif;
  max-height: 94px;
  overflow: hidden;
  margin-bottom: 14px;
}
.blog-single-page .blog_single-body .tour-block .item_right .item_text {
  color: #919193;
  font-size: 18px;
  max-height: 74px;
  overflow: hidden;
}
.blog-single-page .blog_single-body .tour-block .item_right .info {
  position: absolute;
  left: 0;
  bottom: 0;
  padding: 0 44px 30px;
}
.blog-single-page .blog_single-body .tour-block .item_right .info .days {
  font-size: 21px;
  color: rgba(0, 0, 0, 0.53);
  width: auto;
  margin-right: 8px;
  line-height: 40px;
}
.blog-single-page .blog_single-body .tour-block .item_right .info .cost {
  font-size: 21px;
  font-weight: 700;
  color: #000;
  width: auto;
  line-height: 40px;
}
.blog-single-page .blog_single-body .tour-block .item_right .info .sale {
  background: #FF3B00;
  height: 40px;
  line-height: 40px;
  width: auto;
  display: inline-block;
  float: none;
  border-radius: 5px;
  font-size: 21px;
  color: #fff;
  font-weight: 600;
  padding: 0 12px;
  margin: 0 12px;
}
.blog-single-page .blog_single-body .tour-block .item_right .add_bookmark {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background: #ffffff;
  position: absolute;
  right: 30px;
  top: 18px;
}
.blog-single-page .blog_single-body .tour-block .item_right .add_bookmark:hover {
  cursor: pointer;
}
.blog-single-page .blog_single-body .tour-block .item_right .add_bookmark:hover * {
  cursor: pointer;
}
.blog-single-page .blog_single-body .tour-block .item_right .add_bookmark .not-added {
  width: 18px;
  height: 22px;
  /* background: url(../img/bookmark.svg) center center no-repeat; */
  background-size: contain;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -11px;
  margin-left: -9px;
  content: '';
  opacity: 1;
}
.blog-single-page .blog_single-body .tour-block .item_right .add_bookmark .is-added {
  width: 18px;
  height: 22px;
  /* background: url(../img/bookmark.svg) center center no-repeat; */
  background-size: contain;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -11px;
  margin-left: -9px;
  content: '';
  z-index: 2;
}
.blog-single-page .blog_single-body .tour-block .item_right .add_bookmark i.not-added {
  display: block;
  position: relative;
  z-index: 1;
}
.blog-single-page .blog_single-body .tour-block .item_right .add_bookmark i.is-added {
  display: none;
  position: relative;
}
.blog-single-page .blog_single-body .tour-block .item_right .add_bookmark .fav-overlay {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background: #D03000;
  transform: scale(0);
  transition: all .4s;
  z-index: 1;
}
.blog-single-page .blog_single-body .tour-block .item_right .add_bookmark.added .fav-overlay {
  transform: scale(1);
}
.blog-single-page .blog_single-body .tour-block .item_right .add_bookmark.added i.not-added {
  display: none;
}
.blog-single-page .blog_single-body .tour-block .item_right .add_bookmark.added i.is-added {
  display: block;
}
.blog-single-page .blog_single-body .slider-block {
  position: relative;
  margin: 15px 0;
}
.blog-single-page .blog_single-body .slider-block .arrows {
  position: absolute;
  z-index: 2;
  top: 38px;
  right: 40px;
  width: auto;
}
.blog-single-page .blog_single-body .slider-block .arrows .arrow {
  width: 44px;
  height: 44px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.42);
  position: relative;
  transition: all .2s linear;
}

.blog-single-page .blog_single-body .slider-block .arrows .arrow:after {
  width: 16px;
  height: 12px;
  /* background: url(../img/right-arrow.svg) center center no-repeat; */
  background-size: contain;
  position: absolute;
  content: '';
  top: 50%;
  left: 50%;
  margin-top: -6px;
  margin-left: -8px;
  opacity: 0;
  transition: all .2s linear;
  transform: rotate(180deg);
}
.blog-single-page .blog_single-body .slider-block .arrows .arrow.next {
  margin-left: 18px;
  transform: rotate(180deg);
}
.blog-single-page .blog_single-body .slider-block .arrows .arrow:hover {
  background: #fff;
  transition: all .2s linear;
  cursor: pointer;
}
.blog-single-page .blog_single-body .slider-block .arrows .arrow:hover:before {
  opacity: 0;
  transition: all .2s linear;
}
.blog-single-page .blog_single-body .slider-block .arrows .arrow:hover:after {
  opacity: 1;
  transition: all .2s linear;
}
.blog-single-page .blog_single-body .slider-block .slider_top {
  margin-bottom: 20px;
  overflow: hidden;
  border-radius: 8px;
}
.blog-single-page .blog_single-body .slider-block .slider_top .slide {
  width: 1085px;
  margin: 0 2px 0 3px;
  height: 524px;
  border-radius: 8px;
  overflow: hidden;
  display: block;
  float: left;
}
.blog-single-page .blog_single-body .slider-block .slider_top .slide img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  display: block;
}
.blog-single-page .blog_single-body .slider-block .slider_bottom {
  border-radius: 8px;
  overflow: hidden;
}
.blog-single-page .blog_single-body .slider-block .slider_bottom .slide {
  width: 198px;
  height: 140px;
  border-radius: 8px;
  overflow: hidden;
  margin-right: 24px;
  transition: all .2s linear;
}
.blog-single-page .blog_single-body .slider-block .slider_bottom .slide img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  display: block;
}
.blog-single-page .blog_single-body .slider-block .slider_bottom .slide.slick-current {
  opacity: .5;
  transition: all .2s linear;
}
.blog-single-page .right_content .category_block {
  margin-top: 0;
}
.blog-single-page .comments-block {
  margin-top: 45px;
}
.blog-single-page.left-sidebar .left_content {
  float: right;
}
.blog-single-page.left-sidebar .right_content {
  float: left;
}
/*----------------------------------------------------------------------------------
2. Media queries
-----------------------------------------------------------------------------------*/
@media screen and (max-width: 1520px) {
  .blog-single-page .blog_single-body .stories .story_item {
    width: 222px;
    height: 360px;
    margin-right: 20px;
  }
  .blog-single-page .blog_single-body .tour-block .item_right {
    max-width: 530px;
  }
  .blog-single-page .blog_single-body .tour-block .item_left {
    max-width: 350px;
  }
  .blog-single-page .blog_single-body .tour-block .item_left .image {
    height: 21em;
  }
  .blog-single-page .blog_single-body .tour-block .item_right {
    max-width: 570px;
    min-height: 336px;
  }
  .blog-single-page .blog_single-body .tour-block .item_right .item_title {
    font-size: 26px;
    max-height: 74px;
  }
  .blog-single-page .blog_single-body .tour-block .item_right .item_text {
    font-size: 16px;
    max-height: 68px;
  }
  .blog-single-page .blog_single-body .tour-block .item_right .info .days {
    font-size: 18px;
  }
  .blog-single-page .blog_single-body .tour-block .item_right .info .cost {
    font-size: 18px;
  }
  .blog-single-page .blog_single-body .tour-block .item_right .info {
    padding-bottom: 20px;
  }
  .blog-single-page .blog_single-body .tour-block .item_right .info .sale {
    font-size: 18px;
    line-height: 34px;
    height: 34px;
  }
  .blog-single-page .blog_single-body .slider-block .slider_top .slide {
    width: 945px;
  }
  .blog-single-page .blog_single-body .slider-block .slider_bottom .slide {
    width: 170px;
    height: 114px;
  }
  .blog-single-page .blog_single-body .quote p {
    font-size: 22px;
  }
}
@media screen and (max-width: 1300px) {
  .blog-single-page .blog_single-body .stories .story_item {
    width: 32%;
    height: 400px;
    margin-right: 2%;
  }
  .blog-single-page .blog_single-body .stories .story_item:nth-child(3) {
    margin-right: 0;
  }
  .blog-single-page .blog_single-body .stories .story_item:last-child {
    display: none;
  }
  .blog-single-page .blog_single-body .tour-block .item_right {
    max-width: 464px;
  }
  .blog-single-page .blog_single-body .slider-block .slider_top .slide {
    width: 835px;
  }
  .blog-single-page .blog_single-body .slider-block .slider_bottom .slide {
    width: 148px;
  }
  .blog-single-page .blog_single-body .slider-block .slider_top .slide {
    height: 446px;
  }
  .blog-single-page .blog_single-body .slider-block .full-width-link {
    top: 368px;
  }
}
@media screen and (max-width: 1200px) {
  .blog-single-page .blog_single-head_top {
    padding: 30px;
    height: 394px;
  }
  .blog-single-page .blog_single-head_top .title {
    font-size: 38px;
  }
  .blog-single-page .blog_single-body .description {
    font-size: 24px;
  }
  .blog-single-page .blog_single-body .stories .story_item {
    height: 327px;
  }
  .story_item ._content .text {
    font-size: 18px;
  }
  .blog-single-page .blog_single-body .two-colums .col {
    width: 100%;
    margin-right: 0;
  }
  .blog-single-page .blog_single-body .tour-block .item_left {
    max-width: 100%;
  }
  .blog-single-page .blog_single-body .tour-block .item_right {
    max-width: 100%;
    margin-top: 10px;
  }
  .blog-single-page .blog_single-body .slider-block .slider_top .slide {
    width: 605px;
  }
  .blog-single-page .blog_single-body .slider-block .slider_bottom .slide {
    width: 186px;
  }
  .blog-single-page .blog_single-body .slider-block .slider_top .slide {
    height: 340px;
  }
  .blog-single-page .blog_single-body .slider-block .full-width-link {
    top: 270px;
  }
  .blog-single-page .blog_single-body .slider-block .arrows {
    top: 24px;
    right: 26px;
  }
  .blog-single-page .blog_single-body .slider-block .full-width-link {
    right: 26px;
  }
}
@media screen and (max-width: 1040px) {
  .blog-single-page .blog_single-body .tour-block .item_left .shadow {
    display: none;
  }
  .blog-single-page .blog_single-body .slider-block .arrows .arrow {
    background: #fff;
  }
  .blog-single-page .blog_single-body .slider-block .arrows .arrow:before {
    opacity: 0;
  }
  .blog-single-page .blog_single-body .slider-block .arrows .arrow:after {
    opacity: 1;
  }
}
@media screen and (max-width: 1000px) {
  .page .left_content {
    width: 100%;
    max-width: 100%;
  }
  .blog-list-page .page_body .right_content {
    max-width: 100%;
    width: 100%;
  }
  .blog-single-page .blog_single-body .stories .story_item {
    height: 52vw;
  }
  .blog-single-page .blog_single-body .slider-block .slider_bottom .slide {
    margin-right: 14px;
    height: 18vw;
  }
  .blog-single-page .blog_single-body .slider-block .slider_top .slide {
    margin-left: 0;
    margin-right: 14px;
    height: 42vw;
  }
  .blog-single-page .blog_single-body .slider-block .slider_top {
    margin-bottom: 14px;
  }
  .blog-single-page .blog_single-body .slider-block .arrows {
    right: 34px;
  }
  .blog-single-page .blog_single-body .slider-block .full-width-link {
    top: 24px;
    left: 20px;
    right: auto;
  }
  .blog-single-page .right_content {
    margin-top: 40px;
  }
}
@media screen and (max-width: 760px) {
  .blog-single-page .blog_single-head_top .title {
    text-align: left;
  }
  .blog-single-page .blog_single-body .stories h2 {
    overflow: auto;
    margin-left: -5%;
    width: 110%;
    padding: 0 5vw;
  }
  .blog-single-page .blog_single-body .stories .scroll {
    overflow: auto;
    margin-left: -5%;
    width: 110%;
    padding-left: 5vw;
    padding-bottom: 12px;
  }
  .blog-single-page .blog_single-body .stories .scroll .scroll_wrap {
    width: auto;
    white-space: nowrap;
  }
  .blog-single-page .blog_single-body .stories .scroll .scroll_wrap .story_item {
    white-space: normal;
    display: inline-block;
    height: 400px;
    float: none;
  }
  .blog-single-page .blog_single-body .stories .scroll .scroll_wrap .story_item:nth-child(3) {
    margin-right: 2%;
  }
  .blog-single-page .blog_single-body .stories .scroll .scroll_wrap .story_item:last-child {
    margin-right: 5vw;
    display: inline-block;
  }
  .blog-single-page .page_body {
    overflow: visible;
  }
  .blog-single-page .blog_single-body .gallery-block {
    overflow: auto;
    margin-left: -5%;
    width: 110%;
    padding-left: 5vw;
    padding-bottom: 12px;
  }
  .blog-single-page .blog_single-body .gallery-block .scroll {
    width: auto;
    white-space: nowrap;
  }
  .blog-single-page .blog_single-body .gallery-block .scroll .img {
    white-space: normal;
    display: inline-block;
    float: none;
    width: 300px;
    height: 300px;
    margin-right: 14px;
    margin-bottom: 0;
  }
  .blog-single-page .blog_single-body .gallery-block .scroll .img:last-child {
    margin-right: 5vw;
  }
  .blog-single-page .blog_single-body .tour-block .item_right {
    padding-left: 14px;
    padding-right: 14px;
  }
  .blog-single-page .blog_single-body .tour-block .item_right .info {
    padding-left: 14px;
    padding-right: 14px;
  }
  .blog-single-page .blog_single-body .tour-block .item_left .image {
    height: 50vw;
  }
  .blog-single-page .blog_single-body .tour-block .item:last-child {
    margin-bottom: 0;
  }
  .blog-single-page .blog_single-body .slider-block .slider_top .slide {
    margin-right: 0;
  }
  .blog-single-page .blog_single-body .slider-block .slider_bottom .slide {
    margin-right: 0;
    margin: 0 7px;
  }
  .blog-single-page .blog_single-body .slider-block .arrows {
    right: 20px;
  }
  .blog-single-page .blog_single-head_top .title {
    font-size: 26px;
  }
  .blog-single-page .blog_single-body .description {
    font-size: 20px;
  }
  .blog-single-page .blog_single-body p:not([class]) {
    font-size: 18px;
  }
  .blog-single-page .blog_single-body h2 {
    font-size: 28px;
  }
}
@media screen and (max-width: 640px) {
  .blog-single-page .blog_single-body .tour-block .item_right .add_bookmark {
    width: 50px;
    height: 50px;
    right: 14px;
  }
  .blog-single-page .blog_single-body .tour-block .item_right .add_bookmark .fav-overlay {
    width: 50px;
    height: 50px;
  }
  .blog-single-page .blog_single-body .tour-block .item_right .add_bookmark .is-added {
    width: 14px;
    margin-left: -7px;
  }
  .blog-single-page .blog_single-body .tour-block .item_right .add_bookmark .not-added {
    width: 14px;
    margin-left: -7px;
  }
  .blog-single-page .blog_single-body .tour-block .item_right .info .days {
    font-size: 15px;
  }
  .blog-single-page .blog_single-body .tour-block .item_right .info .cost {
    font-size: 15px;
  }
  .blog-single-page .blog_single-body .tour-block .item_right .info .sale {
    font-size: 15px;
    margin: 0 4px;
  }
  .blog-single-page .blog_single-head_top {
    padding: 20px;
  }
  .blog-single-page .blog_single-head_top {
    height: 100vw;
    min-height: 355px;
  }
  .blog-single-page .blog_single-body .quote {
    padding: 24px;
    padding-top: 60px;
  }
  .blog-single-page .blog_single-body .quote:before {
    top: 28px;
    left: 24px;
    width: 18px;
    height: 18px;
  }
  .blog-single-page .blog_single-body .slider-block .slider_top .slide {
    height: 80vw;
  }
  .blog-single-page .blog_single-body .slider-block .slider_bottom .slide {
    height: 26vw;
  }
}
@media screen and (max-width: 480px) {
  .blog-single-page .blog_single-body .stories .scroll .scroll_wrap .story_item {
    height: 360px;
  }
  .blog-single-page .blog_single-body .gallery-block .scroll .img {
    width: 272px;
    height: 272px;
  }
}

.buy-btn {
  height: 70px;
  width: 70px;
  font-size: 16px;
  background: #F03800;
  border-radius: 50%;
  display: flex;
  align-items: center;
  text-align: center;
  color: #fff;
  padding: 0 4px;
  font-weight: 700;
  position: fixed;
  bottom: 24px;
  right: 24px;
  z-index: 100;
}
.buy-btn:hover {
  background: #F5673C;
}
