*::-webkit-scrollbar {
  width: 10px;
}

*::-webkit-scrollbar-track {
  display: none;
}

*::-webkit-scrollbar-thumb {
  background-color: red;
  border-radius: 50px;
}
img{
  object-fit: cover;
}

.demo-wrap {
  overflow: hidden;
  position: relative;
  background: #000000;
  height: fit-content;
}

.demo-bg {
  opacity: 0.6;
  position: absolute;
  background: #000000;
  left: 0;
  top: 0;
  width: 100%;
  height: fit-content;
}

.demo-content {
  position: relative;
}

.custom-length{
  width: fit-content;
  height: fit-content;
}
.vertical-divider {
  border-left: 6px solid green;
  height: 20px;
}
.site-hero {
  background-size: cover;
  height: 100vh;
  min-height: 700px;
  width: 100%;
  position: relative;
}
.site-hero .heading {
  font-family: "Playfair Display", times, serif;
  font-weight: bold;
}
.site-hero .scroll-down {
  position: absolute;
  bottom: 20px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  color: #fff;
}
.site-hero.overlay:before {
  background: rgba(0, 0, 0, 0.45);
  content: "";
  position: absolute;
  height: 100vh;
  min-height: 700px;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
.site-hero.inner-page {
  height: 50vh;
  min-height: 700px;
}
.site-hero.inner-page.overlay:before {
  height: 50vh;
  min-height: 700px;
}

.site-hero-inner {
  height: 100vh;
  min-height: 700px;
}
.site-hero-inner .heading {
  font-size: 80px;
  font-family: "Playfair Display", times, serif;
  color: #fff;
  line-height: 1;
  font-weight: bold;
}
@media (max-width: 991.98px) {
  .site-hero-inner .heading {
    font-size: 40px;
  }
}
.site-hero-inner .sub-heading {
  font-size: 30px;
  font-weight: 200;
  color: #fff;
  line-height: 1.5;
}
@media (max-width: 991.98px) {
  .site-hero-inner .sub-heading {
    font-size: 18px;
  }
}

.launching {
  width: 100%;
  position: absolute;
  background-image: url(../../images/hero.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.launching h1 {
  position: relative;
  color: #fff;
  font-size: 100px;
  font-weight: bolder;
}
/* Mouse Animation */
.mouse {
  width: 100px;
  display: inline-block;
  position: absolute;
  left: 50%;
  bottom: 140px;
  z-index: 1;
  -webkit-transform: translateX(-25%);
  -ms-transform: translateX(-25%);
  transform: translateX(-25%);
}

.mouse-icon {
  width: 25px;
  height: 45px;
  border: 2px solid white;
  border-radius: 15px;
  cursor: pointer;
  position: relative;
  text-align: center;
}

.mouse-wheel {
  height: 6px;
  margin: 2px auto 0;
  display: block;
  width: 3px;
  background-color: white;
  border-radius: 50%;
  -webkit-animation: 1.6s ease infinite wheel-up-down;
  -moz-animation: 1.6s ease infinite wheel-up-down;
  animation: 1.6s ease infinite wheel-up-down;
}

@-webkit-keyframes wheel-up-down {
  0% {
    margin-top: 2px;
    opacity: 0;
  }
  30% {
    opacity: 1;
  }
  100% {
    margin-top: 20px;
    opacity: 0;
  }
}

@-moz-keyframes wheel-up-down {
  0% {
    margin-top: 2px;
    opacity: 0;
  }
  30% {
    opacity: 1;
  }
  100% {
    margin-top: 20px;
    opacity: 0;
  }
}

@keyframes wheel-up-down {
  0% {
    margin-top: 2px;
    opacity: 0;
  }
  30% {
    opacity: 1;
  }
  100% {
    margin-top: 20px;
    opacity: 0;
  }
}
.hotel-carousel {
  height: 70vh;
}
.hotel-carousel h1 {
  font-size: 80px;
  /* margin-bottom: 15%; */
}
.hotel-carousel p {
  margin-bottom: 13%;
  font-size: 22px;
}

.hotel-carousel img {
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
}
@media screen and (max-width: 768px) {
  .hotel-carousel {
    height: 40vh;
  }
}
.room-backend {
  background: linear-gradient(rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.9)),
    url(../../images/hotel-resto-5.jpg) no-repeat center top;
  background-size: cover;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-attachment: fixed;
}
.room-hero {
  width: 100%;
  height: 45vh;
  display: flex;
  justify-content: space-around;
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url(../../images/hotel-resto-3.jpg);
  background-size: cover;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-attachment: fixed;
}
.block-2 {
  margin-bottom: 50px;
  -webkit-perspective: 1000;
  -moz-perspective: 1000;
  -ms-perspective: 1000;
  perspective: 1000;
  -ms-transform: perspective(1000px);
  -moz-transform: perspective(1000px);
  -moz-transform-style: preserve-3d;
  -ms-transform-style: preserve-3d;
}
.block-2:hover .back,
.block-2.hover .back {
  -webkit-transform: rotateY(0deg);
  -moz-transform: rotateY(0deg);
  -o-transform: rotateY(0deg);
  -ms-transform: rotateY(0deg);
  transform: rotateY(0deg);
}
.block-2:hover .front,
.block-2.hover .front {
  -webkit-transform: rotateY(180deg);
  -moz-transform: rotateY(180deg);
  -o-transform: rotateY(180deg);
  transform: rotateY(180deg);
}
.block-2,
.block-2 .front,
.block-2 .back {
  width: 100%;
  height: 427px;
}
.block-2 .flipper {
  -webkit-transition: 0.6s;
  -webkit-transform-style: preserve-3d;
  -ms-transition: 0.6s;
  -moz-transition: 0.6s;
  -moz-transform: perspective(1000px);
  -moz-transform-style: preserve-3d;
  -ms-transform-style: preserve-3d;
  -o-transition: 0.6s;
  transition: 0.6s;
  transform-style: preserve-3d;
  position: relative;
}
.block-2 .front,
.block-2 .back {
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  backface-visibility: hidden;
  border-radius: 4px;
  -webkit-transition: 0.6s;
  -webkit-transform-style: preserve-3d;
  -webkit-transform: rotateY(0deg);
  -moz-transition: 0.6s;
  -moz-transform-style: preserve-3d;
  -moz-transform: rotateY(0deg);
  -o-transition: 0.6s;
  -o-transform-style: preserve-3d;
  -o-transform: rotateY(0deg);
  -ms-transition: 0.6s;
  -ms-transform-style: preserve-3d;
  -ms-transform: rotateY(0deg);
  transition: 0.6s;
  transform-style: preserve-3d;
  transform: rotateY(0deg);
  position: absolute;
  top: 0;
  left: 0;
}
.block-2 .front {
  -webkit-transform: rotateY(0deg);
  -ms-transform: rotateY(0deg);
  background: lightgreen;
  z-index: 2;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}
.block-2 .front:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: -moz-linear-gradient(
    top,
    transparent 0%,
    transparent 18%,
    rgba(0, 0, 0, 0.8) 99%,
    rgba(0, 0, 0, 0.8) 100%
  );
  background: -webkit-linear-gradient(
    top,
    transparent 0%,
    transparent 18%,
    rgba(0, 0, 0, 0.8) 99%,
    rgba(0, 0, 0, 0.8) 100%
  );
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(transparent),
    color-stop(18%, transparent),
    color-stop(99%, rgba(0, 0, 0, 0.8)),
    to(rgba(0, 0, 0, 0.8))
  );
  background: -o-linear-gradient(
    top,
    transparent 0%,
    transparent 18%,
    rgba(0, 0, 0, 0.8) 99%,
    rgba(0, 0, 0, 0.8) 100%
  );
  background: linear-gradient(
    to bottom,
    transparent 0%,
    transparent 18%,
    rgba(0, 0, 0, 0.8) 99%,
    rgba(0, 0, 0, 0.8) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#cc000000',GradientType=0 );
}
.block-2 .front .box {
  position: absolute;
  bottom: 0;
  left: 20px;
  right: 20px;
  bottom: 20px;
  border-radius: 5px;
}
.block-2 .front .box h2,
.block-2 .front .box p {
  color: #fff;
  margin: 0;
  padding: 0;
  line-height: 1.5;
}
.block-2 .front .box h2 {
  font-size: 20px;
}
.block-2 .front .box p {
  font-size: 12px;
}
.block-2 .back {
  background: #fff;
  -webkit-box-shadow: 0 0 70px -10px rgba(0, 0, 0, 0.4);
  box-shadow: 0 0 70px -10px rgba(0, 0, 0, 0.4);
  -webkit-transform: rotateY(-180deg);
  -moz-transform: rotateY(-180deg);
  -o-transform: rotateY(-180deg);
  -ms-transform: rotateY(-180deg);
  transform: rotateY(-180deg);
}
.block-2 .back p {
  position: absolute;
  top: 40px;
  left: 0;
  right: 0;
  text-align: center;
  padding: 0 20px;
  font-size: 18px;
}
.block-2 .author {
  bottom: 0;
  position: absolute;
  bottom: 20px;
  left: 20px;
  right: 20px;
}
.block-2 .author .image {
  width: 40px;
}
.block-2 .author .image img {
  border-radius: 50%;
  max-width: 100%;
}
.block-2 .author .position {
  display: block;
  font-size: 12px;
}
@media (max-width: 991.98px) {
  .block-2 .back {
    -webkit-transform: rotateY(0deg);
    -moz-transform: rotateY(0deg);
    -o-transform: rotateY(0deg);
    -ms-transform: rotateY(0deg);
    transform: rotateY(0deg);
  }
  .block-2 .front {
    -webkit-transform: rotateY(180deg);
    -moz-transform: rotateY(180deg);
    -o-transform: rotateY(180deg);
    transform: rotateY(180deg);
  }
}
hr.ourRooms {
  border: 3px solid red;
  width: 15%;
  margin: auto;
  font-weight: bold;
  text-align: center;
}
hr.facilities {
  border: 3px solid red;
  width: 15%;
}
/* tourism style */
.tourism {
  background-image: url(../../images/ele.jpg);

  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.holiday-sidebar {
  color: #000;
  height: 90vh;
}
/*--------------------------------------------------------------
# About
--------------------------------------------------------------*/
.about {
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  padding: 80px 0;
  background-position: center center;
}

.about:before {
  content: "";
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
}

.about .about-img {
  position: relative;
  transition: 0.5s;
}

.about .about-img img {
  max-width: 100%;
  border: 4px solid rgba(255, 255, 255, 0.2);
  position: relative;
}
@media print {
  .hide-on-print {
    display: none;
  }
  .on-print {
    margin-left: 0%;
    background: #000000;
  }
}

.on-print {
  margin-left: 0%;
}

.about .about-img::before {
  position: absolute;
  left: 20px;
  top: 20px;
  width: 60px;
  height: 60px;
  z-index: 1;
  content: "";
  border-left: 5px solid #cda45e;
  border-top: 5px solid #cda45e;
  transition: 0.5s;
}

.about .about-img::after {
  position: absolute;
  right: 20px;
  bottom: 20px;
  width: 60px;
  height: 60px;
  z-index: 2;
  content: "";
  border-right: 5px solid #cda45e;
  border-bottom: 5px solid #cda45e;
  transition: 0.5s;
}

.about .about-img:hover {
  transform: scale(1.03);
}

.about .about-img:hover::before {
  left: 10px;
  top: 10px;
}

.about .about-img:hover::after {
  right: 10px;
  bottom: 10px;
}

.about .content h3 {
  font-weight: 600;
  font-size: 26px;
}

.about .content ul {
  list-style: none;
  padding: 0;
}

.about .content ul li {
  padding-bottom: 10px;
}

.about .content ul i {
  font-size: 20px;
  padding-right: 4px;
  color: #cda45e;
}

.about .content p:last-child {
  margin-bottom: 0;
}

@media (min-width: 1024px) {
  .about {
    background-attachment: fixed;
  }
}

/*--------------------------------------------------------------
# Why Us
--------------------------------------------------------------*/

.why-us .box {
  padding: 50px 30px;
  box-shadow: 0px 2px 15px rgba(78, 78, 78, 0.1);
  transition: all ease-in-out 0.3s;
  background: #dddcdc;
}

.why-us .box span {
  display: block;
  font-size: 28px;
  font-weight: 700;
  color: #cda45e;
}

.why-us .box h4 {
  font-size: 24px;
  font-weight: 600;
  padding: 0;
  margin: 20px 0;
  color: rgb(0, 0, 0);
}

.why-us .box p {
  color: #000000;
  font-size: 15px;
  margin: 0;
  padding: 0;
}

.why-us .box:hover {
  background: #0c0101;
  padding: 30px 30px 70px 30px;
  box-shadow: 10px 15px 30px rgba(0, 0, 0, 0.18);
}

.why-us .box:hover span,
.why-us .box:hover h4,
.why-us .box:hover p {
  color: #fff;
}
.profileImage img {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  border: 2px solid red;
}

.display-img .tour-img:hover {
  transform: scale(1.03);
  transition: 0.5s;
}
.input-tag {
  background: white;
  border: 1px solid #d6d6d6;
  border-radius: 2px;
  display: flex;
  flex-wrap: wrap;
  padding: 5px 5px 0;
}

.input-tag input {
  border: none;
  width: 100%;
}

.input-tag__tags {
  display: inline-flex;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
  width: 100%;
}

.input-tag__tags li {
  align-items: center;
  background: #85a3bf;
  border-radius: 2px;
  color: white;
  display: flex;
  font-weight: 300;
  list-style: none;
  margin-bottom: 5px;
  margin-right: 5px;
  padding: 5px 10px;
}

.input-tag__tags li button {
  align-items: center;
  appearance: none;
  background: #333333;
  border: none;
  border-radius: 50%;
  color: white;
  cursor: pointer;
  display: inline-flex;
  font-size: 12px;
  height: 15px;
  justify-content: center;
  line-height: 0;
  margin-left: 8px;
  padding: 0;
  transform: rotate(45deg);
  width: 15px;
}

.input-tag__tags li.input-tag__tags__input {
  background: none;
  flex-grow: 1;
  padding: 0;
}

.active_category {
  color: #fff;
  background-color: #2f855a;
}
.inter-sidebar li:hover {
  transition: 0.5s ease-in-out;
  border-radius: 8px;
}
.inter-sidebar li {
  transition: 0.5s ease-in-out;
  border-radius: 8px;
  color: #fff;
}
#active-sidebar {
  background-color: #dddcdc52;
  color: #fff;
  /* width: 100%; */
  margin-top: 2px;
  transition: 0.5s ease-in-out;
}
/* toggle style */
.toggle-switch {
  position: relative;
  margin-right: 10px;
  width: 50%;
  display: inline-block;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  text-align: left;
}
.toggle-switch-checkbox {
  display: none;
}
.toggle-switch-label {
  display: block;
  overflow: hidden;
  cursor: pointer;
  border: 0 solid #bbb;
  border-radius: 20px;
  margin: 0;
}
.toggle-switch-label:focus {
  outline: none;
}
.toggle-switch-label:focus > span {
  box-shadow: 0 0 2px 5px red;
}
.toggle-switch-label > span:focus {
  outline: none;
}
.toggle-switch-inner {
  display: block;
  width: 200%;
  margin-left: -100%;
  transition: margin 0.3s ease-in 0s;
}
.toggle-switch-inner:before,
.toggle-switch-inner:after {
  display: block;
  float: left;
  width: 50%;
  height: 34px;
  padding: 0;
  line-height: 34px;
  font-size: 14px;
  color: white;
  font-weight: bold;
  box-sizing: border-box;
}
.toggle-switch-inner:before {
  content: attr(data-yes);
  text-transform: uppercase;
  padding-left: 10px;
  background-color: #2f855a;
  color: #fff;
}
.toggle-switch-disabled {
  background-color: #ddd;
  cursor: not-allowed;
}
.toggle-switch-disabled:before {
  background-color: #ddd;
  cursor: not-allowed;
}
.toggle-switch-inner:after {
  content: attr(data-no);
  text-transform: uppercase;
  padding-right: 10px;
  background-color: #bbb;
  color: #000;
  text-align: right;
}
.toggle-switch-switch {
  display: block;
  width: 54px;
  margin: 5px;
  background: #fff;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 60px;
  border: 0 solid #bbb;
  border-radius: 20px;
  transition: all 0.3s ease-in 0s;
}
.toggle-switch-checkbox:checked + .toggle-switch-label .toggle-switch-inner {
  margin-left: 0;
}
.toggle-switch-checkbox:checked + .toggle-switch-label .toggle-switch-switch {
  right: 0px;
}
.toggle-switch.small-switch {
  width: 40px;
}
.toggle-switch.small-switch .toggle-switch-inner:after,
.toggle-switch.small-switch .toggle-switch-inner:before {
  content: "";
  height: 20px;
  line-height: 20px;
}
.toggle-switch.small-switch .toggle-switch-switch {
  width: 16px;
  right: 20px;
  margin: 2px;
}
@media screen and (max-width: 991px) {
  .toggle-switch {
    transform: scale(0.9);
  }
}
@media screen and (max-width: 767px) {
  .toggle-switch {
    transform: scale(0.825);
  }
}
@media screen and (max-width: 575px) {
  .toggle-switch {
    transform: scale(0.75);
  }
}

/*# sourceMappingURL=styles.css.map */
/* count down styles */
.countdown-container {
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  align-items: center;
  margin: 0.5rem;
}

.countdown-container > form {
  padding: 0.5rem;
}

.countdown-container > form > label {
  margin-right: 1rem;
}

.expired-notice {
  text-align: center;
  padding: 2rem;
  border: 1px solid #ebebeb;
  border-radius: 0.25rem;
  margin: 0.5rem;
}

.expired-notice > h1 {
  font-size: 2.5rem;
  font-weight: bold;
  color: red;
}

.expired-notice > h2 {
  font-size: 1.5rem;
}

.show-counter {
  padding: 0.5rem;
}

.show-counter .countdown-link {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  font-size: 2.25rem;
  line-height: 1.75rem;
  padding: 0.5rem;
  border-radius: 0.25rem;
  text-decoration: none;
  color: #fff;
}

.show-counter .countdown {
  line-height: 1.25rem;
  padding: 0 0.75rem 0 0.75rem;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.show-counter .countdown.danger {
  color: #ff0000;
}

.show-counter .countdown > p {
  margin: 0;
}

.show-counter .countdown > span {
  text-transform: uppercase;
  font-size: 1.5rem;
  line-height: 2rem;
}

@media only screen and (max-width: 500px) {
  .show-counter .countdown > span {
    font-size: 1rem;
    line-height: 1rem;
  }

  .show-counter .countdown-link {
    font-size: 1.5rem;
  }
}

.dropActive {
  background: #646464;
}
.dropUnActive {
  background: #dfdfdf;
}

.dropZone {
  border: 1px dashed;
  margin: auto;
  width: 100%;
}

blockquote {
  /* background: #f9f9f9; */
  border-left: 10px solid #ccc;
  margin: 1.5em 10px;
  padding: 0.5em 10px;
  quotes: "\201C""\201D""\2018""\2019";
}
blockquote:before {
  /* color: #ccc; */
  content: open-quote;
  font-size: 4em;
  line-height: 0.1em;
  margin-right: 0.25em;
  vertical-align: -0.4em;
}
blockquote p {
  display: inline;
}

/* TOGGLE BUTTON */

.toggle-switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 30px;
}

.toggle-input {
  opacity: 0;
  width: 0;
  height: 0;
}

.toggle-span {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #2c3e50;
  transition: 0.3s;
  border-radius: 30px;
}

strong {
  position: absolute;
  left: 100%;
  width: max-content;
  height: 100%;
  line-height: 30px;
  margin-left: 10px;
  cursor: pointer;
}

.toggle-span:before {
  position: absolute;
  content: "";
  height: 25px;
  width: 25px;
  left: 3px;
  bottom: 2.6px;
  background-color: #fff;
  border-radius: 50%;
  transition: 0.3s;
}

.toggle-input:checked + .toggle-span {
  background-color: #00c853;
}

.toggle-input:checked + .toggle-span:before {
  transform: translateX(29px);
}

.static_input {
  width: 25%;
}
