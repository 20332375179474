.loginimg {
  width: 100%;
}
.login {
  height: 100vh;
  width: 100%;
  background: radial-gradient(#0f0914, #0e0813);
  position: relative;
}

.login_box .left {
  width: 100%;
  height: 100%;
  padding: 25px 25px;
}

.left .contact {
  /* display: flex; */
  align-items: center;
  justify-content: center;
  align-self: center;
  height: 100%;
  width: 40%;
  margin: auto;
}

.left input {
  border: none;
  border-radius: 8px;
  width: 50%;
  margin: 15px 0px;
  border-bottom: 1px solid #4f30677d;
  padding: 15px 20px;
  width: 100%;
  overflow: hidden;
  background: rgba(255, 255, 255, 0.664);
  font-weight: 600;
  font-size: 14px;
}
.left select {
  border: none;
  border-radius: 8px;
  width: 50%;
  margin: 15px 0px;
  border-bottom: 1px solid #4f30677d;
  padding: 15px 20px;
  width: 100%;
  overflow: hidden;
  background: rgba(255, 255, 255, 0.664);
  font-weight: 600;
  font-size: 14px;
}
.left {
  background: linear-gradient(
      212.38deg,
      rgba(242, 57, 127, 0.7) 0%,
      rgba(27, 9, 29, 0.71) 100%
    ),
    url(../../images/city.jpg);
  color: #fff;
  background-repeat: no-repeat;
  background-position: center center;
  background-attachment: fixed;
  background-size: cover;
  position: relative;
}

.top_link img {
  width: 28px;
  padding-right: 7px;
  margin-top: -3px;
}
@media screen and (max-width: 768px) {
  .right {
    display: none;
  }
  .login_box {
    width: 100%;
  }
  .right-text h2 {
    font-size: 25px;
  }
  .right-text h5 {
    font-size: 20px;
  }
}
