@import url("https://fonts.googleapis.com/css2?family=Poller+One&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");

$secondaryFont: "Poller One", cursive;
$primaryFont: "Poppins", sans-serif;

$primaryColor: #0e0e0e;
$primaryColorLight: #171bf5;
$secondaryColor: #121218;
$tertiaryColor: #12cdc9;

.logo {
  font-size: 36px;
  color: $secondaryColor;
  font-family: $secondaryFont;
  text-align: center;
  margin: 50px 0;
}

.home-container {
  background-color: $primaryColor;
  min-height: 600px;
  height: 100vh;
  max-height: 100vh;
  overflow: hidden;
  display: flex;

  img {
    width: 24px;
    cursor: pointer;
    height: 24px;
  }

  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.1);
    z-index: 1;
  }

  .side {
    width: 320px;
    box-sizing: border-box;
    background-color: $primaryColorLight;
    position: relative;
    transition: 0.5s ease;

    .top {
      padding: 30px;
      img {
        width: 18px;
        height: 18px;
      }
    }
  }

  .main {
    flex: 1;
    display: flex;
    flex-direction: column;

    .heading {
      border-bottom: 2px solid rgba(white, 0.1);
      padding: 0 50px;
      height: 80px;

      @media screen and (max-width: 480px) {
        padding: 0 20px;
      }

      .rightItems {
        img {
          @media screen and (max-width: 480px) {
            width: 20px;
            height: 20px;
          }
        }
        img:first-child {
          width: 18px;
          height: 18px;
          margin-right: 20px;

          @media screen and (max-width: 480px) {
            width: 15px;
            height: 15px;
          }
        }
      }
    }
  }

  @media screen and (max-width: 768px) {
    display: block;
    position: relative;

    .side {
      position: fixed;
      left: 0;
      bottom: 0;
      z-index: 11;
      top: 0;

      &.close {
        left: -350px;

        & ~ .overlay {
          display: none;
        }
      }
    }
    .main {
      position: absolute;
      width: 100%;
      height: 100%;
    }
  }
}

.mobile {
  display: none;
}

.desktop {
  display: block;
}

@media screen and (max-width: 768px) {
  .mobile {
    display: block;
  }
  .desktop {
    display: none;
  }
}

.searchCon {
  margin: 0 30px;
  margin-bottom: 30px;
  background-color: #3b3b65;
  height: 35px;
  border-radius: 40px;
  position: relative;
  overflow: hidden;

  input {
    width: 100%;
    height: 100%;
    background-color: #3b3b65;
    border: none;
    outline: none;
    padding-left: 40px;
    box-sizing: border-box;
    color: white;
  }

  img {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 10px;
  }
}

.userAvatar {
  display: flex;
  align-items: center;
  .imageCon {
    width: 50px;
    height: 50px;
    border-radius: 50px;
    background-color: darken($primaryColor, 5%);
    margin-right: 15px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

    @media screen and (max-width: 480px) {
      width: 35px;
      height: 35px;
    }
  }

  .name {
    font-size: 16px;
    font-weight: 500;
    color: white;

    @media screen and (max-width: 480px) {
      font-size: 13px;
    }
  }

  .subContent {
    font-size: 12px;
    color: rgba(white, 0.4);

    @media screen and (max-width: 480px) {
      font-size: 10px;
    }
  }
  &.version2 {
    .imageCon {
      width: 40px;
      height: 40px;
      margin-right: 10px;
      background-color: darken($primaryColor, 5%);
    }

    .name {
      font-size: 13px;
      line-height: 15px;
    }
    .subContent {
      font-size: 10px;
      color: rgba(white, 0.4);
    }
  }
}

.logout {
  position: absolute;
  bottom: 20px;
  left: 30px;

  font-size: 10px;
  color: rgba(white, 0.1);
}
.leftHook {
  flex: 1;
  margin-right: 50px;
  display: flex;
  align-items: center;
  flex-direction: column;

  .imageCon {
    width: 100px;
    height: 100px;
    border-radius: 100px;
    margin-bottom: 10px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }

  .point {
    font-size: 12px;
    display: flex;
    align-items: center;
    cursor: pointer;

    img {
      margin-left: 5px;
    }
  }
}

.authContainer {
  background-color: $primaryColor;
  width: 100%;
  min-height: 100vh;
}

.loader {
  width: 30px;
  height: 30px;
  border: 2px solid #09ff00;
  border-left-color: transparent;
  border-bottom-color: transparent;
  border-right-color: transparent;
  border-radius: 30px;
  animation: rotate 500ms linear infinite;
}

.centerAll {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
}

@keyframes rotate {
  to {
    transform: rotateZ(360deg);
  }
}

.errorHolder {
  background-color: red;
  color: white;
  padding: 10px;
  margin-bottom: 20px;

  position: relative;

  img {
    position: absolute;
    top: 5px;
    right: 5px;
    width: 10px;
    cursor: pointer;
  }
}

.noUser {
  font-size: 14px;
  color: white;
  text-align: center;
}

.clickable {
  cursor: pointer;
}
