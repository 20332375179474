#top-header {
  background-color: #2f3e4e;
  height: 50px;
  border-bottom: 1px solid #008000;
  padding-left: 260px;
  transition: all 0.3s;
  position: fixed;
  top: 0;
  z-index: 9;
  width: 100%;
}
#top-header i {
  margin: 15px 15px;
  cursor: pointer;
  font-size: 20px;
}
#top-header .user {
  float: right;
}

#left-menu .profile {
  margin: auto;
  align-items: center;
}

#left-menu .profile img {
  width: 100px;
  height: 100px;
  border: 4px solid gold;
  border-radius: 50%;
}

#left-menu,
#logo {
  width: 250px;
  background-color: #2f3e4e;
  color: #99abba;
}

#logo {
  margin-top: 10px;
  height: 50px;
  font-weight: bold;
  border-bottom: 1px solid #008000;
  text-align: center;
  font-size: 15px;
  position: fixed;
  z-index: 15;
  transition: all 0.3s;
  top: 0;
  left: 0;
}
#logo span {
  transition: all 0.3s ease;
}
#left-menu {
  height: 100vh;
  top: 40px;
  position: fixed;
  overflow-x: hidden;
  overflow-y: auto;
  transition: all 0.3s;
  z-index: 15;
}

#left-menu ul h5 {
  margin-left: 7px;
}

#left-menu ul {
  padding: 0;
  margin: 0;
  padding-top: 15px;
  margin-bottom: 40px;
}
#left-menu ul li {
  list-style-type: none;
  height: 50px;
  width: 250px;
}

#left-menu ul li:hover {
  background: #ffd700;
  color: #99abba;
}

#left-menu ul li a {
  color: #99abba;
  padding: 10px 15px;
  display: inline-block;
  width: 100%;
}
#left-menu ul li a:hover {
  color: #fff;
}
#left-menu ul li.active a {
  color: #d74703;
}
#left-menu ul li a span {
  display: inline-block;
  vertical-align: middle;
  margin-top: -7px;
  padding-left: 10px;
  transition: all 0.3s;
}

#left-menu ul li a i {
  font-size: 20px;
}
#warraper.small-menu #logo {
  width: 60px;
  border-bottom: 1px solid transparent;
}
#warraper.small-menu #logo span {
  opacity: 0;
  visibility: hidden;
}
#warraper.small-menu #left-menu {
  width: 60px;
  top: 0;
  overflow-x: hidden;
  overflow-y: auto;
}
#warraper.small-menu #left-menu span {
  opacity: 0;
  visibility: hidden;
  left: 0;
}
#warraper.small-menu #main-content {
  margin-left: 80px;
}
#warraper.small-menu #top-header {
  padding-left: 70px;
}
#main-content {
  margin-left: 230px;
  margin-top: 20px;
  margin-right: 20px;
  transition: all 0.3s;
  margin-top: 60px;
}

#main-content .title {
  padding: 10px 0;
  border-bottom: 1px solid #ccc;
  margin-bottom: 10px;
}

#show-label-menu {
  position: fixed;
  width: 200px;
  overflow-x: hidden;
  left: 60px;
  z-index: 16;
  padding: 10px 15px;
  top: 0;
  background-color: #212c37;
  color: #99abba;
  display: none;
  transition: all 0.2s;
}

@media only screen and (max-width: 772px) {
  #top-header #toggle-menu {
    display: none;
  }

  .logo img{
    width: 50%;
    height: 80px;
  }

  /* #logo {
      top: -50px;
    } */
  #left-menu {
    width: 0px;
  }

  /* #left-menu span {
      opacity: 0;
      visibility: hidden;
    } */
  #main-content {
    margin-left: 20px;
  }
  #top-header {
    padding-left: 70px;
  }
}
